import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { deleteNoticeAsync, getNoticeAsync } from "src/api/notice/notice-api";
import {
  NoticeBuildingDto,
  NoticeContractDto,
  NoticeModel,
  NoticeType,
} from "src/api/notice/notice-types";
import { BaseButton, BaseModal, ContentsIdSection, ContentsTitle } from "src/components";
import MarkdownViewer from "src/components/MarkdownViewer";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import { Modal, changeTextNoticeType } from "../notice-types";

/*
  공지사항 상세
*/
const NoticeDetail = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // path variable 에서 noticeId 추출
  const noticeId = useMemo(() => {
    const paths = location.pathname.split("/");
    const pathNoticeId = paths[paths.length - 1];
    return Number(pathNoticeId);
  }, [location]);

  // 공지사항 상세
  const [notice, setNotice] = useState<
    | (NoticeModel & { contractSpaceProductNames: Array<string>; buildingNames: Array<string> })
    | undefined
  >();

  const { executeAsync: getNotice } = useApiOperation(getNoticeAsync);
  const { executeAsync: deleteNotice } = useApiOperation(deleteNoticeAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      console.log("clickBreadCrumb", crumb);
      if (crumb.value === "notice") {
        navigate(PagePath.notice.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "notice", label: "공지사항" }];

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    // 모달 닫기
    setConfirmModal({ isOpen: false });

    if (confirmModal.type === "NOTICE_DELETE") {
      // 공지사항 삭제
      const { data: deleteNoticeResultData } = await deleteNotice({
        id: noticeId,
      });
      if (deleteNoticeResultData?.data?.notice) {
        openToast(`정상적으로 삭제 되었습니다.`);
        // 목록으로 이동
        navigate(PagePath.notice.list);
      }
    }
  };

  // useEffect(() => {
  //   if (noticeId) {
  //     async function fetchNotice(noticeId: number) {
  //       setLoadingBar(true);
  //       const { data } = await getNotice({ noticeId });
  //       const notice = data?.data?.notice;
  //       const medias = data?.data?.medias;

  //       setNotice(notice);
  //       setNoticeFiles(medias);
  //       setLoadingBar(false);
  //     }
  //     fetchNotice(noticeId);
  //   }
  // }, [getNotice, noticeId, setLoadingBar]);
  useEffect(() => {
    const fetchApi = async (noticeId: number) => {
      setLoadingBar(true);
      const { data } = await getNotice({ noticeId });
      if (data.data) {
        let tempNoticeDetail = data.data.notice;
        let contractSpaceProductNames: Array<string> = [];
        let buildingNames: Array<string> = [];
        if (tempNoticeDetail.contracts && tempNoticeDetail.contracts.length > 0) {
          const contractList = tempNoticeDetail.contracts.map(
            (item: NoticeContractDto) => item.contractId,
          );
          const { data: contractData } = await getContractList({
            page: 0,
            size: 99,
            contractIds: contractList?.join(","),
          });
          contractSpaceProductNames = contractData.data.content.map(
            (contract) => contract.spaceProductName,
          );
        }
        if (tempNoticeDetail.buildings && tempNoticeDetail.buildings.length > 0) {
          const buildingList = tempNoticeDetail.buildings.map(
            (item: NoticeBuildingDto) => item.buildingId,
          );
          const { data: buildingData } = await getBuildingList({
            page: 0,
            size: 99,
            id: buildingList?.join(","),
          });
          buildingNames = buildingData.data.content.map((building) => building.buildingName);
        }
        const newData = { ...tempNoticeDetail, contractSpaceProductNames, buildingNames };
        setNotice(newData);
      }
      setLoadingBar(false);
    };
    if (noticeId) {
      fetchApi(noticeId);
    }
  }, [noticeId, getContractList, getBuildingList, getNotice, setLoadingBar]);

  useTitleOperation(notice?.title);

  return (
    <div>
      <ContentsTitle title="공지사항" breadCrumbs={breadCrumbs} clickBreadCrumb={clickBreadCrumb} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <ContentsIdSection title="공지사항 정보" id={noticeId} />
            <article>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>게시 위치</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className="font14 pre-formatted">
                      {[notice?.isDisplayTaap && "Taap", notice?.isDisplayTaapspace && "Taapspace"]
                        .filter(Boolean)
                        .join(", ") || "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>공지 유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className="font14 pre-formatted">
                      {notice?.type ? changeTextNoticeType(notice?.type) : "-"}
                    </span>
                  </div>
                </div>
              </section>
              {notice?.type === "SPACE" && (
                <>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p className="">신청/계약</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p className="font14">
                          {notice?.contractSpaceProductNames.join(", ") || "-"}
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p className="">건물</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div>
                        <p className="font14">{notice?.buildingNames.join(",") || "-"}</p>
                      </div>
                    </div>
                  </section>
                </>
              )}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>제목</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="">
                    <span className="font14">{notice?.title}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index">
                  <p>내용</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div>
                    {notice?.content ? (
                      <MarkdownViewer value={notice.content || ""} />
                    ) : (
                      <div className="flex-row flex-center-start">
                        <p>-</p>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>공개 여부</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14 pre-formatted">
                      {notice?.isDisplayed === true ? (
                        <span className="chip green">공개</span>
                      ) : (
                        <span className="chip gray">비공개</span>
                      )}
                    </span>
                  </div>
                </div>
              </section>
              {notice?.isDisplayed && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>공개 기간</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14 pre-formatted">
                        {notice?.displayStartDate && notice?.displayEndDate
                          ? `${moment(notice?.displayStartDate).format(
                              YmdFormat.YYYY_MM_DD_HH_MM,
                            )} ~ ${moment(notice?.displayEndDate).format(
                              YmdFormat.YYYY_MM_DD_HH_MM,
                            )}`
                          : "-"}
                      </span>
                    </div>
                  </div>
                </section>
              )}

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>생성일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {notice?.createdDate
                        ? moment(notice?.createdDate).format(YmdFormat.FULL)
                        : "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최초 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">{notice?.createdBy || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 수정일</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {notice?.lastModifiedDate
                        ? moment(notice?.lastModifiedDate).format(YmdFormat.FULL)
                        : "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>최종 작성자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {notice?.lastModifiedBy ? notice?.lastModifiedBy : "-"}
                    </span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax300">
                    <span className="font14">
                      {!notice?.partnerId
                        ? "-"
                        : Number(notice?.partnerId) > 0
                        ? "핀포인트 (pinpoint)"
                        : "all"}
                    </span>
                  </div>
                </div>
              </section>
            </article>
          </div>
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (noticeId) {
                    // 수정화면에서는 목록으로 바로이동
                    navigate(PagePath.notice.list);
                  } else {
                    // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                    navigate(-1);
                  }
                }}
              />
              <BaseButton
                title="삭제"
                className="no-outline color-white size-large text-red ml10"
                onClick={() =>
                  setConfirmModal({
                    isOpen: true,
                    type: "NOTICE_DELETE",
                  })
                }
              />
            </div>
            <div className="right-area">
              <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const formPath = `${PagePath.notice.form}?id=${noticeId}`;
                  navigate(formPath);
                }}
              />
            </div>
          </div>

          {/* 삭제 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            <p>
              삭제한 내용은 복구가 불가합니다.
              <br />
              삭제 하시겠습니까?
            </p>
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
