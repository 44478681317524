import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  patchRegisteredDeviceControlAsync,
  postRegisteredDeviceControlAsync,
} from "src/api/iot/iot-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { smartThingsControlMethodToString } from "../iot-types";

type Props = {
  registeredDeviceId: string;
  smartThingsModalOpen: Modal;
  setSmartThingsModalOpen: Function;
  isSuccessChanged: (isSuccess: Boolean) => void; // 등록 || 수정 성공시 true return
};

// SmartThings 제어 등록 / 수정 모달

const SmartThingsControlModal = (props: Props) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState<Modal>({ isOpen: false });
  const { executeAsync: postRegisteredDeviceControl } = useApiOperation(
    postRegisteredDeviceControlAsync,
  );
  const { executeAsync: patchRegisteredDeviceControl } = useApiOperation(
    patchRegisteredDeviceControlAsync,
  );
  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    register,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<any>({
    defaultValues: {
      method: "READ_STATUS",
      displayName: "",
      componentId: "",
      capabilityId: "",
    },
    mode: "onChange",
  });
  const onSubmit = useCallback(async (data: any, e?: any) => {
    e.preventDefault();
    let payload = { ...data };
    setConfirmModalOpen({ isOpen: true, message: "저장하시겠습니까?", payload });
  }, []);

  const onError = useCallback((errors: any, e?: any) => {
    throw Error("onError", errors);
  }, []);

  const onClickConfirmModal = async (registeredDeviceId: string, payload: any) => {
    if (props.smartThingsModalOpen.type === "ADD") {
      if (!payload.commandPart?.command && !payload.commandPart?.arguments) {
        delete payload.commandPart;
      }
      if (!payload.commandPart?.command) {
        delete payload.commandPart?.command;
      }
      if (!payload.commandPart?.arguments) {
        delete payload.commandPart?.arguments;
      } else {
        payload = {
          ...payload,
          commandPart: {
            command: payload.commandPart.command,
            arguments: [payload.commandPart.arguments],
          },
        };
      }

      const { data, status } = await postRegisteredDeviceControl({ registeredDeviceId, payload });
      if (status >= 200 && status < 300) {
        props.isSuccessChanged(true);
        reset();
        setConfirmModalOpen({ isOpen: false });
        props.setSmartThingsModalOpen({ isOpen: false });
      } else {
        setConfirmModalOpen({ isOpen: false });
      }
    } else if (props.smartThingsModalOpen.type === "EDIT") {
      const { data, status } = await patchRegisteredDeviceControl({
        registeredDeviceId,
        controlId: props.smartThingsModalOpen.payload.id,
        displayName: payload.displayName,
        overridePlatformDeviceId: payload.overridePlatformDeviceId,
        ...(payload.commandPart?.command && {
          commandPart: {
            command: payload.commandPart.command,
            arguments: [payload.commandPart.arguments],
          },
        }),
      });
      if (status >= 200 && status < 300) {
        props.isSuccessChanged(true);
        reset();
        setConfirmModalOpen({ isOpen: false });
        props.setSmartThingsModalOpen({ isOpen: false });
      } else {
        setConfirmModalOpen({ isOpen: false });
        throw Error("수정실패");
      }
    }
  };

  useEffect(() => {
    if (props.smartThingsModalOpen.type === "EDIT") {
      setValue("method", props.smartThingsModalOpen.payload?.method);
      setValue("displayName", props.smartThingsModalOpen.payload?.displayName);
      setValue("commandPart.command", props.smartThingsModalOpen.payload?.commandPart?.command);
      setValue("commandPart.arguments", props.smartThingsModalOpen.payload?.commandPart?.arguments);
      setValue(
        "overridePlatformDeviceId",
        props.smartThingsModalOpen.payload?.overridePlatformDeviceId,
      );
    }
  }, []);

  useEffect(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("displayName", {
      required: requiredMessage,
    });
    if (props.smartThingsModalOpen.type === "ADD") {
      register("componentId", {
        required: requiredMessage,
      });
      register("capabilityId", {
        required: requiredMessage,
      });
    }
    if (props.smartThingsModalOpen.payload?.method === "EXEC_COMMANDS") {
      register("commandPart.command", {
        required: requiredMessage,
      });
    }
  }, [register, props.smartThingsModalOpen]);

  return (
    <>
      <BaseAbstractModal isOpen={props.smartThingsModalOpen.isOpen}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <section className="base-abstract-modal__title">
            <h1>SmartThings 제어</h1>
          </section>
          <article className="base-abstract-modal__contents">
            <div className="contents-container__wrap">
              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="required">제어 구분</p>
                </div>
                <div className="contents-container__grid-contents">
                  {props.smartThingsModalOpen.type === "ADD" ? (
                    <div>
                      <Controller
                        control={control}
                        name={"method"}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <div className="flex-center">
                            <BaseRadio
                              id={`READ_STATUS`}
                              name={name}
                              value={"READ_STATUS"}
                              checked={value === "READ_STATUS" ? true : false}
                              onChange={(_, e) => {
                                setValue("method", e.currentTarget.value);
                              }}
                              label="상태조회"
                              className="mr10"
                            />
                            <BaseRadio
                              id={`EXEC_COMMANDS`}
                              name={name}
                              value={"EXEC_COMMANDS"}
                              checked={value === "EXEC_COMMANDS" ? true : false}
                              onChange={(_, e) => {
                                setValue("method", e.currentTarget.value);
                              }}
                              label="명령"
                            />
                          </div>
                        )}
                      ></Controller>
                    </div>
                  ) : (
                    <div>
                      <p>
                        {smartThingsControlMethodToString(
                          props.smartThingsModalOpen.payload?.method,
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </section>

              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="required">제어명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name={"displayName"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        className="minmax320"
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </section>

              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="required">Component Id</p>
                </div>
                <div className="contents-container__grid-contents">
                  {props.smartThingsModalOpen.type === "ADD" ? (
                    <Controller
                      control={control}
                      name={"componentId"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          value={value}
                          name={name}
                          onChange={onChange}
                          className="minmax320"
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  ) : (
                    <div>
                      <p>{props.smartThingsModalOpen.payload?.componentId}</p>
                    </div>
                  )}
                </div>
              </section>
              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="required">Capability Id</p>
                </div>
                <div className="contents-container__grid-contents">
                  {props.smartThingsModalOpen.type === "ADD" ? (
                    <Controller
                      control={control}
                      name={"capabilityId"}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          value={value}
                          name={name}
                          onChange={onChange}
                          className="minmax320"
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  ) : (
                    <div>
                      <p>{props.smartThingsModalOpen.payload?.capabilityId}</p>
                    </div>
                  )}
                </div>
              </section>
              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p
                    className={
                      props.smartThingsModalOpen.payload?.method === "READ_STATUS" ||
                      watch("method") === "READ_STATUS"
                        ? ""
                        : "required"
                    }
                  >
                    Command
                  </p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name={"commandPart.command"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        className="minmax320"
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </section>
              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="">Arguments</p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name={"commandPart.arguments"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        className="minmax320"
                      />
                    )}
                  ></Controller>
                </div>
              </section>
              <section className="contents-container__grid pb10">
                <div className="contents-container__grid-index">
                  <p className="">Device ID override</p>
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name={"overridePlatformDeviceId"}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        className="minmax320"
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </section>
            </div>
          </article>
          <section className="base-abstract-modal__btn-wrap ">
            <BaseButton
              title={"취소"}
              type="button"
              className="color-white flex-center-center"
              onClick={() => {
                reset();
                props.setSmartThingsModalOpen({ isOpen: false });
              }}
            />
            <BaseButton title={"저장"} type="submit" className="text-center flex-center-center" />
          </section>
        </form>
      </BaseAbstractModal>
      <BaseModal
        isOpen={confirmModalOpen.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModalOpen({ isOpen: false })}
        onClick={() => {
          onClickConfirmModal(props.registeredDeviceId, confirmModalOpen.payload);
        }}
      >
        {confirmModalOpen.message}
      </BaseModal>
    </>
  );
};

export default SmartThingsControlModal;
