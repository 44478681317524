import { Cell } from "react-table";
import { PaIotLabel, PaRegisteredDeviceControl } from "src/api/iot/iot-types";
import { smartThingsControlMethodToString } from "../../../iot-types";
import { BaseButton, BaseInput, BaseModal, BaseTooltip } from "src/components";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/api/public-types";
import SmartThingsControlModal from "../../../components/SmartThingsControlModal";
import { useLocation, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import {
  deleteRegisteredDeviceControlAsync,
  getIotRegisteredDeviceLabelsAsync,
  postRegisteredDeviceControlExecuteAsync,
} from "src/api/iot/iot-api";
import qs from "qs";
import _ from "lodash";
import { LabelsModal } from "src/pages/iot/components/LabelsModal";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import BaseGaryTooltip from "src/components/BaseGaryTooltip";
import { useLoadingBarContext } from "src/pages/hooks";

interface CellProps {
  row: {
    original: PaRegisteredDeviceControl;
  };
  data: PaRegisteredDeviceControl[];
  fetchListApi: (id: string) => void; // fetchApi 함수의 정확한 타입에 맞게 수정
  setRegisteredDeviceControlList: Function;
}

// 제어실행 결과값 parse 여부 확인
const isValidJSONString = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const columns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 70,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return <p className="no-wrap">{props.value}</p>;
    },
  },
  {
    Header: "제어 구분",
    accessor: "method",
    width: 80,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{smartThingsControlMethodToString(props.value || "-")}</p>
        </div>
      );
    },
  },
  {
    Header: "제어명",
    accessor: "displayName",
    width: 210,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "Component ID",
    accessor: "componentId",
    width: 150,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "Capability ID",
    accessor: "capabilityId",
    width: 140,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "Command",
    accessor: "commandPart.command",
    width: 150,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "Arguments",
    accessor: "commandPart.arguments",
    width: 140,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value?.length > 0 ? props.value.join(", ") : "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "Device ID override",
    accessor: "overridePlatformDeviceId",
    width: 140,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value ?? "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "실행",
    accessor: "bin",
    width: 80,
    Cell: (props: CellProps) => {
      const original = props.row.original;
      const location = useLocation();
      const { id } = useParams();
      const [executeControlModal, setExecuteControlModal] = useState<Modal>({ isOpen: false });
      const [inputArguments, setInputArguments] = useState<any>(
        original.commandPart?.arguments ? String(original.commandPart?.arguments) : "",
      );

      const { executeAsync: postRegisteredDeviceControlExecute } = useApiOperation(
        postRegisteredDeviceControlExecuteAsync,
      );

      const queryParams: any = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      });

      const registeredDeviceId = id || queryParams?.id;
      const executeControl = useCallback(async () => {
        const cloneRegisteredControlList = _.cloneDeep(props.data);
        const findExecuteRow = cloneRegisteredControlList.find((item) => item.id === original.id);

        let checkInputArguments =
          inputArguments == null || inputArguments === ""
            ? null
            : isNaN(inputArguments)
            ? inputArguments.trim()
            : Number(inputArguments);

        // 앞뒤로 따옴표가 있다면, 제거하여 출력
        if (/^['"](.+)['"]$/.test(checkInputArguments)) {
          checkInputArguments = checkInputArguments.slice(1, -1);
        }

        const { data, status } = await postRegisteredDeviceControlExecute({
          registeredDeviceId,
          controlId: original.id,
          payload: {
            arguments:
              original.method === "EXEC_COMMANDS" && checkInputArguments != null
                ? [checkInputArguments]
                : [],
          },
        });
        if (status >= 200 && status < 300) {
          if (findExecuteRow) {
            findExecuteRow.executeResult = isValidJSONString(data.data.result)
              ? JSON.stringify(JSON.parse(data.data.result), null, 4)
              : data.data.result;
          }

          const updateRegisteredControlList = cloneRegisteredControlList.map((control) => {
            if (control.id === original.id) {
              const newCommandPart = {
                ...control.commandPart,
                arguments:
                  original.method === "EXEC_COMMANDS" && checkInputArguments != null
                    ? [checkInputArguments]
                    : [],
              };
              const newControl = {
                ...control,
                commandPart: newCommandPart,
              };
              return newControl;
            } else return control;
          });
          props.setRegisteredDeviceControlList(updateRegisteredControlList);
        }
      }, [
        props,
        registeredDeviceId,
        postRegisteredDeviceControlExecute,
        original.id,
        inputArguments,
      ]);
      useEffect(() => {
        if (original.commandPart?.arguments) {
          setInputArguments(String(original.commandPart?.arguments));
        }
      }, []);

      return (
        <>
          <BaseButton
            title="실행"
            className="color-white size-small"
            onClick={() => {
              original.method === "EXEC_COMMANDS"
                ? setExecuteControlModal({ isOpen: true })
                : executeControl();
            }}
          />
          {executeControlModal.isOpen && (
            <BaseAbstractModal
              isOpen={true}
              size="small"
              // onClose={() => setExecuteControlModal({ isOpen: false })}
              // onClick={() => executeControl()}
              className="execute-control-modal"
            >
              <section className="base-abstract-modal__title">
                <h1>제어 실행</h1>
              </section>
              <section className="base-abstract-modal__contents px30">
                <div className="in-line">
                  <h4>제어명</h4>
                  <p>{original.displayName}</p>
                </div>
                <div className="in-line mt20">
                  <h4>arguments</h4>
                  <BaseInput
                    value={inputArguments}
                    onChange={(value: string) => {
                      setInputArguments(value);
                    }}
                  />
                </div>
              </section>
              <section className="base-abstract-modal__btn-wrap">
                <BaseButton
                  type="button"
                  title={"취소"}
                  className="color-white flex-center-center"
                  onClick={() => {
                    setExecuteControlModal({ isOpen: false });
                  }}
                />
                <BaseButton
                  type="submit"
                  title={"확인"}
                  className="flex-center-center"
                  onClick={() => {
                    executeControl();
                    setExecuteControlModal({ isOpen: false });
                  }}
                />
              </section>
            </BaseAbstractModal>
          )}
        </>
      );
    },
  },
  {
    Header: "실행 결과",
    accessor: "executeResult",
    width: 120,
    Cell: (props: Cell<PaRegisteredDeviceControl>) => {
      return (
        <div className="flex-center-start w-100">
          <p className="w-100 text-left ellipsis2">{props.value || "-"}</p>
          {props.value && <BaseGaryTooltip touchIcon="INFO" children={<>{props.value}</>} />}
        </div>
      );
    },
  },
  {
    Header: "Labels",
    accessor: "labels",
    width: 80,
    Cell: (props: CellProps & Cell<PaRegisteredDeviceControl>) => {
      const { id } = useParams();
      const [isLabelsModalOpen, setIsLabelsModalOpen] = useState(false);
      const controlId = props.row.original.id;

      const renderJSON = () => {
        if (props.value && props.value.length > 0) {
          try {
            const changeJSONtoText = JSON.stringify(props.value, null, 2);
            return changeJSONtoText;
          } catch (e) {
            throw e;
          }
        } else {
          return null;
        }
      };

      return (
        <div className="flex-center-start w-100">
          <BaseButton
            title="수정"
            className="color-white size-small"
            onClick={() => {
              setIsLabelsModalOpen(true);
            }}
          />
          {renderJSON() && (
            <BaseGaryTooltip className="ml4" touchIcon="INFO" children={<>{renderJSON()}</>} />
          )}

          {isLabelsModalOpen && (
            <LabelsModal
              types="CONTROL"
              registeredDeviceId={String(id)}
              setIsLabelModalOpen={setIsLabelsModalOpen}
              controlId={String(controlId)}
              fetchListApi={props.fetchListApi}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: "수정 / 삭제",
    accessor: "bin2",
    width: 80,
    Cell: (props: CellProps) => {
      const [editModalOpen, setEditModalOpen] = useState<Modal>({ isOpen: false });
      const [confirmModalOpen, setConfirmModalOpen] = useState<Modal>({ isOpen: false });
      const original = props.row.original;
      const { id } = useParams();
      const location = useLocation();
      const { executeAsync: deleteRegisteredDeviceControl } = useApiOperation(
        deleteRegisteredDeviceControlAsync,
      );
      const queryParams: any = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      });

      const registeredDeviceId = id || queryParams?.id;

      const deleteControl = useCallback(async () => {
        const { status } = await deleteRegisteredDeviceControl({
          registeredDeviceId,
          controlId: original.id,
        });
        if (status >= 200 && status < 300) {
          setConfirmModalOpen({ isOpen: false });
          props.fetchListApi(registeredDeviceId);
        }
      }, [props, registeredDeviceId, deleteRegisteredDeviceControl, original.id]);

      return (
        <>
          <div className="minmax50 flex-center">
            <button
              className="base-edit-btn mr4"
              onClick={() => setEditModalOpen({ isOpen: true, type: "EDIT", payload: original })}
            ></button>
            <button
              className="base-trash-btn"
              onClick={() => {
                setConfirmModalOpen({ isOpen: true, message: "정말 삭제하시겠습니까?" });
              }}
            ></button>
          </div>
          {registeredDeviceId && editModalOpen.isOpen && (
            <SmartThingsControlModal
              registeredDeviceId={registeredDeviceId}
              smartThingsModalOpen={editModalOpen}
              setSmartThingsModalOpen={setEditModalOpen}
              isSuccessChanged={(isSuccess: Boolean) => {
                isSuccess === true && props.fetchListApi(registeredDeviceId);
              }}
            />
          )}
          {confirmModalOpen.isOpen && (
            <BaseModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="삭제"
              onClose={() => setConfirmModalOpen({ isOpen: false })}
              onClick={() => deleteControl()}
            >
              <p className="pre-formatted">{confirmModalOpen.message}</p>
            </BaseModal>
          )}
        </>
      );
    },
  },
];
