import React from "react";
import { PopbillBizDetailModel } from "src/api/popbill/popbill-types";

type Props = {
  popbill?: PopbillBizDetailModel;
};

const PopbillVerifiedInfo = ({ popbill }: Props) => {
  return (
    <section className="contents-container__1200">
      <div className="mb10">
        <div className="index-tab">
          <span>연동 상태</span>
        </div>
        <div className="border-gray py16 pr16 px20">
          <section className="flex-center">
            <div className="minmax200 d-flex">
              <span className="font13 text-primary3">세금 계산서 발행 API : </span>
              <span className=" font13 ml10">
                {popbill?.isVerifiedTaxInvoice ? "성공" : "실패"}
              </span>
            </div>

            <div className="minmax200">
              <span className="font13 text-primary3">계좌 조회 API : </span>
              <span className=" font13 ml10">
                {popbill?.isVerifiedBankAccount ? "성공" : "실패"}
              </span>
            </div>

            <div className="minmax200">
              <span className="font13 text-primary3">현금영수증 API : </span>
              <span className=" font13 ml10">{popbill?.isVerifiedCashbill ? "성공" : "실패"}</span>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PopbillVerifiedInfo;
