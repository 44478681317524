import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";
import BaseInfo from "./basicInfo/BasicInfo";
import Partner from "./partnerLink/Partner";

import { useLoadingBarContext } from "src/pages/hooks";
import { getCctvList } from "src/api/cctv/cctv-api";
import { CctvDevice } from "src/api/cctv/cctv-type";
import { useApiOperation } from "src/api/hooks";
const CctvDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: cctvId } = useParams();
  const [cctv, setCctv] = useState<CctvDevice>();
  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: getCctvDetail } = useApiOperation(getCctvList);

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    { value: "partnerLink", label: "파트너" },
    // { value: "로그", label: "로그" },
    // { value: "adminMemo", label: "관리자 메모" }, // api 만들어지면 노출예정
  ];

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "product") {
        navigate(PagePath.product.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "cctv", label: "CCTV 장치" }];
  if (activeTab) {
    // 마지막 bread crumb 는 현재 선택된 탭
    breadCrumbs.push({
      value: activeTab.value,
      label: activeTab.label,
    });
  }

  const callCctvData = useCallback(async () => {
    getCctvDetail({ id: Number(cctvId) }).then((response) => {
      setLoadingBar(true);
      if (response.status >= 200 && response.status < 300) {
        const result: any = response.data.data.content[0];
        setCctv(result);

        // fetchPartner(result?.organization?.partnerId);
      }
      setLoadingBar(false);
    });
  }, [cctvId, getCctvDetail, setLoadingBar]);

  useEffect(() => {
    callCctvData();
  }, [callCctvData]);

  return (
    <div>
      <ContentsTitle
        title="CCTV 장치"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />

      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BaseInfo />}
        {activeTab?.value === "partnerLink" && (
          <Partner detail={cctv} callCctvData={callCctvData} />
        )}
      </div>
    </div>
  );
};

export default CctvDetail;
