// 건물 Table Columns
import moment from "moment";
import BaseGaryTooltip from "src/components/BaseGaryTooltip";
import { YmdFormat } from "src/utils";

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    // sticky: "left",
    width: 80,
    Cell: ({ value }: any) => {
      return <p>{value}</p>;
    },
  },
  {
    Header: "이벤트 발생 시각",
    accessor: "eventTime",
    // sticky: "left",
    width: 200,
    Cell: ({ value }: any) => {
      return <p>{moment(value).format(YmdFormat.FULL)}</p>;
    },
  },

  {
    Header: "Camera Group",
    accessor: "cameraGroup",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value}</p>
        </div>
      );
    },
  },
  {
    Header: "Camera ID",
    accessor: "cameraId",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value}</p>
        </div>
      );
    },
  },
  {
    Header: "이벤트 유형",
    accessor: "eventType",
    width: 160,
    Cell: ({ value }: any) => {
      // const eventTypes = [
      //   { label: "넘어짐", value: "fall" },
      //   { label: "화재", value: "fire" },
      //   { label: "사람수", value: "crowd" },
      //   { label: "무기", value: "weapon" },
      //   { label: "ppe", value: "ppe" },
      // ];

      // const fined = eventTypes.find((ty: any) => ty.value === value);
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value ? value : "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "이벤트 데이터",
    accessor: "eventData",
    width: 340,
    Cell: ({ value }: any) => {
      const renderJSON = () => {
        if (value && value.length > 0) {
          try {
            const changeData = JSON.parse(value);
            const changeJSONtoText = JSON.stringify(changeData, null, 2);
            return changeJSONtoText;
          } catch (e: any) {
            return `JSON 파싱 에러: ${e.message}`;
          }
        } else {
          return null;
        }
      };
      return (
        <div className="flex-center-start w-100">
          <p className="w-100 text-left ellipsis2">{renderJSON() || "-"}</p>
          {renderJSON() && (
            <BaseGaryTooltip className="ml4" touchIcon="INFO" children={<>{renderJSON()}</>} />
          )}
        </div>
      );
    },
  },
];
