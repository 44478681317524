import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { popbillTabs } from "../popbill-constant";
import BasicInfoDetail from "./components/BasicInfoDetail";
import PopbillAccountDetail from "./components/PopbillAccountDetail";
import { getPopbillDetailAsync } from "src/api/popbill/popbill-api";
import { useApiOperation } from "src/api/hooks";
import { useQuery } from "@tanstack/react-query";

const PopbillDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: pbBizId } = useParams();

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const { executeAsync: getPopbillDetail } = useApiOperation(getPopbillDetailAsync);

  const { data: popbill, refetch: refetchPopbill } = useQuery({
    queryKey: ["popbillDetail"],
    queryFn: () => getPopbillDetail({ pbBizId: String(pbBizId) }),
    select: (response) => {
      let result = response.data.data.content;
      result = {
        ...result,
        popbillAcctList: result.popbillAcctList?.sort((a, b) => {
          return a.pbAccountId ? -1 : 1;
        }),
      };

      return result;
    },
  });

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return popbillTabs.find((tab) => tab.value === queryParams?.tab);
    }
    return popbillTabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [location, queryParams],
  );

  return (
    <div>
      <ContentsTitle
        title="팝빌 사업자/계좌"
        tabs={popbillTabs}
        activeTab={activeTab}
        clickTab={clickTab}
      />
      <div className="contents-container__scroll">
        {/* 기본정보 */}
        {activeTab?.value === "basicInfo" && (
          <BasicInfoDetail refetchPopbill={refetchPopbill} popbill={popbill} />
        )}
        {/* 계좌정보 */}
        {activeTab?.value === "accountInfo" && (
          <PopbillAccountDetail refetchPopbill={refetchPopbill} popbill={popbill} />
        )}
      </div>
    </div>
  );
};

export default PopbillDetail;
