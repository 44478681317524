import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceTypes } from "src/api/adminMemo/adminmemo-types";
import { getCctvList } from "src/api/cctv/cctv-api";
import { CctvDevice } from "src/api/cctv/cctv-type";
import { useApiOperation } from "src/api/hooks";

import { getPartner } from "src/api/partner";
import { PageMeta, Sort } from "src/api/public-types";
import { BaseButton, ContentsIdSection } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import TextAttribute from "src/pages/organization/detail/components/TextAttribure";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";

type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
};

const BaseInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: cctvId } = useParams();

  const { setLoadingBar } = useLoadingBarContext();
  const classname = "minmax300";
  const [partner, setPartner] = useState<Partner>();

  // 메모 목록

  // 상세 데이터
  const [detail, setDetail] = useState<CctvDevice>();

  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });

    if (!_queryParams.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams.size) {
      _queryParams.size = 3;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "adminNote.id", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const { executeAsync: getCctvDetail } = useApiOperation(getCctvList);
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner); // 파트너 조회

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    setLoadingBar(true);
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setPartner(partner);
    }
    setLoadingBar(false);
  };

  const goList = useCallback(() => {
    navigate(PagePath.cctv.list);
  }, [navigate]);

  const goEditPage = useCallback(() => {
    const page = PagePath.cctv.form + "?id=" + cctvId!.toString();
    navigate(page);
  }, [navigate, cctvId]);

  useEffect(() => {
    getCctvDetail({ id: Number(cctvId) }).then((response) => {
      setLoadingBar(true);
      if (response.status >= 200 && response.status < 300) {
        const result: any = response.data.data.content[0];
        setDetail(result);

        // fetchPartner(result?.organization?.partnerId);
      }
      setLoadingBar(false);
    });
  }, [getCctvDetail, cctvId, setLoadingBar]);
  useTitleOperation(detail?.name);

  return (
    <>
      <div className="contents-container__wrap detail-basic-info">
        <div className="contents-container__wrap-contents">
          <ContentsIdSection
            title="기본정보"
            rightLables={[{ key: "CCTV ID", value: String(detail?.id) }]}
          />

          <section className="user-basic-info">
            <div className="user-info-container"></div>
          </section>

          <section className="user-basic-info">
            <div className="user-info-container">
              <div className="flex-center">{/* id */}</div>
              <div className="flex-center">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>CCTV ID</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <p>{detail?.id}</p>
                  </div>
                </section>{" "}
              </div>
              <div className="flex-center">
                <TextAttribute title={"Camera Group"} value={detail?.cameraGroup} />
                <TextAttribute title={"Camera ID"} value={detail?.cameraId} />
              </div>
              <div className="flex-center">
                <TextAttribute title={"장치명"} value={detail?.name} classname={classname} />
              </div>
            </div>
          </section>

          <AdminMemoV3
            serviceId={Number(detail?.id)}
            serviceType={ServiceTypes.SERVICE_CCTV_DEVICE}
            partnerId="0"
          ></AdminMemoV3>
        </div>

        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <BaseButton
              title="목록으로"
              type="button"
              className="color-white size-large"
              onClick={goList}
            />
          </div>
          <div className="right-area">
            <BaseButton
              type="button"
              title="수정"
              className="size-large"
              onClick={() => {
                navigate(PagePath.cctv.form + `?id=${cctvId}&tab=basicInfo`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseInfo;
