import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getNoticesAsync } from "src/api/notice/notice-api";
import { NoticeListParams, NoticeModel } from "src/api/notice/notice-types";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { noticeColumns } from "./columns";

// 공지 유형
const containsTypes = [
  { value: "", label: "전체" },
  { value: "SERVICE", label: "서비스 이용" },
  { value: "SPACE", label: "공간 이용" },
];

// 공개 여부
const isDisplayed = [
  { value: "", label: "전체" },
  { value: "true", label: "공개" },
  { value: "false", label: "비공개" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = ["contractProductNames", "buildingNames"];

// url query parameter 타입
type QueryParams = NoticeListParams & {
  page?: number;
  size?: number;
  searchNoticeType?: string;
  searchType?: string;
  searchTarget?: string;
  searchKeyword?: string;
  isDisplayed?: string;
  search001?: string;
  sort?: {
    orders?: Array<Order>;
  };
};

/*
  공지사항 목록 화면
*/
const NoticeList = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "createdDate", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  // 공지사항 목록 조회
  const [notices, setNotices] = useState<NoticeModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getNotices } = useApiOperation(getNoticesAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  useEffect(() => {
    setLoadingBar(true);
    const fetchApi = async (noticeParam: NoticeListParams) => {
      // 공지사항 목록 조회
      const response = await getNotices(noticeParam);
      if (response.status >= 200 && response.status <= 299) {
        const noticeList = response.data.data.rows;
        let tempAllContracts: any = [];
        let tempAllBuildings: any = [];
        noticeList.forEach((notice) => {
          if (notice.contracts) {
            tempAllContracts = [...tempAllContracts, ...notice.contracts];
          }
          if (notice.buildings) {
            tempAllBuildings = [...tempAllBuildings, ...notice.buildings];
          }
        });
        const allContracts = [...new Set(tempAllContracts.map((item: any) => item.contractId))];
        const allBuildings = [...new Set(tempAllBuildings.map((item: any) => item.buildingId))];
        let dataContractList: any = [];
        let dataBuildingList: any = [];

        if (allContracts.length > 0) {
          const { data: contractList } = await getContractList({
            page: 0,
            size: 99,
            contractIds: allContracts.join(","),
          }); // 계약목록 조회
          if (contractList.data.content) {
            dataContractList = contractList.data.content;
          }
        }

        if (allBuildings.length > 0) {
          const { data: buildingList } = await getBuildingList({
            page: 0,
            size: 99,
            id: allBuildings.join(","),
          }); // 건물목록 조회
          if (buildingList.data.content) {
            dataBuildingList = buildingList.data.content;
          }
        }

        //신청계약 상품명, 건물명 추가
        const newNoticeList = noticeList.map((notice, length) => {
          const contractProductNames = dataContractList
            ?.filter((contract: ContractManageList) =>
              notice.contracts
                ?.map((item: any) => item.contractId)
                ?.includes(Number(contract.contractId)),
            )
            .map((contract: any) => contract.spaceProductName);
          const buildingNames = dataBuildingList
            ?.filter((building: BuildingModel) =>
              notice.buildings?.map((item: any) => item.buildingId)?.includes(Number(building.id)),
            )
            .map((building: any) => building.buildingName);
          const newNotice = { ...notice, contractProductNames, buildingNames };
          return newNotice;
        });

        setNotices(newNoticeList);
        setPageMeta(response.data.meta.pageMeta);
      }
      setLoadingBar(false);
    };
    fetchApi(queryParams);
  }, [getNotices, queryParams, setLoadingBar, getContractList, getBuildingList]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const findDirection = () => {
    const findDirection = params?.sort?.orders?.find(
      (order: { property: string; direction: string }) => order.property === "createdDate",
    );
    return findDirection?.direction;
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  useEffect(() => {
    findDirection();
  });

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax140 mr8">
            <BaseSelect
              placeholder="공지 유형"
              value={params?.containsTypes}
              stateOptions={containsTypes}
              setStateValue={(containsTypes: string) => setParams({ ...params, containsTypes })}
            />
          </div>
          <div className="minmax140 mr8">
            <BaseSelect
              placeholder="공개 여부"
              value={params?.isDisplayed}
              stateOptions={isDisplayed}
              setStateValue={(isDisplayed: string) => setParams({ ...params, isDisplayed })}
            />
          </div>
          <BaseInput
            value={params?.searchKeyword || params?.search001 || ""}
            className="minmax220 mr16"
            type="text"
            placeholder="검색어를 입력해주세요"
            onChange={(searchKeyword: string) => {
              if (params.searchTarget) {
                setParams({ ...params, searchKeyword });
              } else {
                setParams({ ...params, search001: searchKeyword });
              }
            }}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
        <div className="right-area">
          <BaseButton title="+ 공지사항 등록" onClick={() => navigate(PagePath.notice.form)} />
        </div>
      </div>

      <BaseTable
        data={notices}
        columns={noticeColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={disabledSortHeaders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
    </div>
  );
};

export default NoticeList;
