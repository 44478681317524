import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  PopbillAccountAllocRequest,
  PopbillBizAddRequest,
  PopbillBizAlloc,
  PopbillBizAllocRequest,
  PopbillBizDetailModel,
  PopbillBizListModel,
  PopbillQueryParams,
} from "./popbill-types";
import { AccountHolderModel } from "../provider/provider-types";

/**CCP01. 팝빌 사업자정보 페이징 목록 조회 */

export const getPopbillListAsync = async (
  axios: AxiosInstance,
  params?: PopbillQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizListModel[] }>>> => {
  return await axios.get(ApiPath.popbill.biz.list, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

/**CCP02. 팝빌 사업자정보 상세 조회 */
export const getPopbillDetailAsync = async (
  axios: AxiosInstance,
  params?: { pbBizId: string },
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizDetailModel }>>> => {
  return await axios.get(ApiPath.popbill.biz.detail.replace("{pbBizId}", String(params?.pbBizId)));
};

/**CCP03. 팝빌 사업자 등록 */
export const addPopbillBizAsync = async (
  axios: AxiosInstance,
  params?: PopbillBizAddRequest,
): Promise<AxiosResponse<ApiResponse<{ pbBizId: number }>>> => {
  return await axios.post(ApiPath.popbill.biz.add, { popbillBiz: params });
};

/**CCP04. 팝빌 사업자 할당정보 조회 */

export const getPopbillBizAllocAsync = async (
  axios: AxiosInstance,
  params?: { pbBizId: string },
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizAlloc[] }>>> => {
  return await axios.get(
    ApiPath.popbill.biz.alloc.detail.replace("{pbBizId}", String(params?.pbBizId)),
  );
};

/**CCP05. 팝빌 사업자 할당정보 등록 */

export const addPopbillBizAllocAsync = async (
  axios: AxiosInstance,
  params?: { pbBizId: string; popbillBizAllocList: PopbillBizAllocRequest[] },
): Promise<AxiosResponse<ApiResponse<{ pbBizId: number[] }>>> => {
  return await axios.post(
    ApiPath.popbill.biz.alloc.add.replace("{pbBizId}", String(params?.pbBizId)),
    { popbillBizAllocList: params?.popbillBizAllocList },
  );
};

/**CCP08. 팝빌 사업자 API 검증*/
export const verifyPopbillBizApiAsync = async (
  axios: AxiosInstance,
  params?: { pbBizId: string },
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizAlloc[] }>>> => {
  return await axios.post(ApiPath.popbill.biz.verify.replace("{pbBizId}", String(params?.pbBizId)));
};

/**CCP11. 팝빌 계좌 예금주 확인*/
export const checkAccountHolderAsync = async (
  axios: AxiosInstance,
  params?: { bankCode: string; accountNumber: string },
): Promise<AxiosResponse<ApiResponse<AccountHolderModel>>> => {
  return await axios.get(
    `/api/central/ct/popbill/acct/verify-account-holder?bankCode=${params?.bankCode}&accountNumber=${params?.accountNumber}`,
  );
};

/**CCP06. 팝빌 계좌 할당정보 조회*/
export const getPopbillAccountAllocAsync = async (
  axios: AxiosInstance,
  params?: { pbAccountId: string },
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizAlloc[] }>>> => {
  return await axios.get(
    ApiPath.popbill.account.alloc.detail.replace("{pbAccountId}", String(params?.pbAccountId)),
  );
};

/**CCP07. 팝빌 계좌 할당정보 등록*/
export const addPopbillAccountAllocAsync = async (
  axios: AxiosInstance,
  params?: { pbAccountId: string; popbillAcctAllocList: PopbillAccountAllocRequest[] },
): Promise<AxiosResponse<ApiResponse<{ content: PopbillBizAlloc[] }>>> => {
  return await axios.post(
    ApiPath.popbill.account.alloc.add.replace("{pbAccountId}", String(params?.pbAccountId)),
    { popbillAcctAllocList: params?.popbillAcctAllocList },
  );
};
