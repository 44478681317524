import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import {
  getIotRegisteredDeviceControlLabelsAsync,
  getIotRegisteredDeviceControlListAsync,
  postRegisteredDeviceControlAsync,
  putIotRegisteredDeviceControlLabelsAsync,
} from "src/api/iot/iot-api";
import { Method, PaRegisteredDeviceControl } from "src/api/iot/iot-types";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import SmartThingsControlModal from "../../components/SmartThingsControlModal";
import { columns } from "./columns/Columns";
import qs from "qs";
import { useLoadingBarContext } from "src/pages/hooks";
import CollectiveControlRegistrationModal from "../../components/CollectiveControlRegistrationModal";

const Control = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: getIotRegisteredDeviceControlList } = useApiOperation(
    getIotRegisteredDeviceControlListAsync,
  );
  const { executeAsync: postRegisteredDeviceControl } = useApiOperation(
    postRegisteredDeviceControlAsync,
  );
  const { executeAsync: putIotRegisteredDeviceControlLabels } = useApiOperation(
    putIotRegisteredDeviceControlLabelsAsync,
  );
  const { executeAsync: getIotRegisteredDeviceControlLabels } = useApiOperation(
    getIotRegisteredDeviceControlLabelsAsync,
  );
  const [registeredDeviceControlList, setRegisteredDeviceControlList] = useState<
    PaRegisteredDeviceControl[]
  >([]);
  const [smartThingsModalOpen, setSmartThingsModalOpen] = useState<Modal>({ isOpen: false });
  const [collectiveRegistrationModal, setCollectiveRegistrationModal] = useState<Modal>({
    isOpen: false,
  });
  const [labelJSON, setLabelJSON] = useState<string>("");
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: any = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const registeredDeviceId = id || queryParams?.id;

  const fetchListApi = useCallback(
    async (id: string) => {
      setLoadingBar(true);
      const { data, status } = await getIotRegisteredDeviceControlList({
        registeredDeviceId,
        sort: {
          orders: [{ property: "id", direction: "DESC" }],
        },
      });
      if (status >= 200 && status < 300) {
        const labelPromiseList = data.data.controls.map(async (item) => {
          const data = await getIotRegisteredDeviceControlLabels({
            registeredDeviceId,
            controlId: String(item.id),
          });
          return { data, controlId: item.id };
        });

        const resultLabels: any = await Promise.all(labelPromiseList);
        const resultData = data.data.controls.map((control) => {
          const findLabel = resultLabels.find((label: any) => {
            return String(label.controlId) === String(control.id);
          });
          return { ...control, labels: findLabel.data.data.data.labels };
        });
        setRegisteredDeviceControlList(resultData);
      }
      setLoadingBar(false);
    },
    [getIotRegisteredDeviceControlList, registeredDeviceId, getIotRegisteredDeviceControlLabels],
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: columns,
      data: registeredDeviceControlList,
      initialState: {
        pageSize: 1000,
      },
      fetchListApi,
      setRegisteredDeviceControlList,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const handleSelectCollectiveRegister = async (selected: any) => {
    const registerResult: any = [];

    for (const item of selected) {
      const commandPayload = {
        displayName: item.displayName,
        method: item.method === "상태 조회" ? Method.READ_STATUS : Method.EXEC_COMMANDS,
        componentId: item.componentId,
        capabilityId: item.capabilityId,
        commandPart: item.arguments
          ? { command: item.command, arguments: [item.arguments] }
          : { command: item.command },
      };
      const { commandPart, ...getPayload } = commandPayload;

      const result = await postRegisteredDeviceControl({
        registeredDeviceId,
        payload: item.method === "상태 조회" ? getPayload : commandPayload,
      });

      registerResult.push({ ...result.data.data.control, labels: item.labels });
    }

    try {
      for (let i = 0; i < registerResult.length; i++) {
        const item = registerResult[i];
        await putIotRegisteredDeviceControlLabels({
          registeredDeviceId,
          controlId: String(item.id),
          labels: selected[i].labels,
        });
      }
    } catch (error) {
      throw Error("에러가 발생했습니다");
    } finally {
      fetchListApi(registeredDeviceId);
    }
  };

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  useEffect(() => {
    if (registeredDeviceId) {
      fetchListApi(registeredDeviceId);
    }
  }, [registeredDeviceId, getIotRegisteredDeviceControlList, fetchListApi]);

  return (
    <>
      <div className="contents-container__wrap-contents">
        <div className="py20 pr30" style={{ maxWidth: tableWidth + 30 }}>
          <div className="flex-row flex-center-end">
            <BaseButton
              title="+ 제어 일괄 등록"
              className="mr10"
              onClick={() =>
                setCollectiveRegistrationModal({ isOpen: true, type: "COLLECTIVE", payload: null })
              }
            />
            <BaseButton
              title="+ 등록"
              onClick={() => setSmartThingsModalOpen({ isOpen: true, type: "ADD", payload: null })}
            />
          </div>
          {registeredDeviceId && (
            <SmartThingsControlModal
              registeredDeviceId={registeredDeviceId}
              smartThingsModalOpen={smartThingsModalOpen}
              setSmartThingsModalOpen={setSmartThingsModalOpen}
              isSuccessChanged={(isSuccess: Boolean) => {
                fetchListApi(registeredDeviceId);
              }}
            />
          )}
          {registeredDeviceId && collectiveRegistrationModal.isOpen && (
            <CollectiveControlRegistrationModal
              registeredDeviceId={registeredDeviceId}
              onCanceled={() => setCollectiveRegistrationModal({ isOpen: false })}
              multiSelect={true}
              onAdded={(selected) => {
                handleSelectCollectiveRegister(selected);
              }}
              defaultValues={[]}
            />
          )}
        </div>
        <div className="inner-tab-table pr30">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                        {/* <div className="ic_sort"></div> */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {page.map((row: any) => {
                prepareRow(row);

                return (
                  <div
                    {...row.getRowProps()}
                    className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="contents-container__btn-wrap px30">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            type="button"
            className="color-white size-large"
            onClick={() => {
              navigate("/mng/central/iot");
            }}
          />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default Control;
