import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { CctvDevice, CctvDevicePartner } from "src/api/cctv/cctv-type";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { Modal } from "src/api/public-types";
import { BaseButton } from "src/components";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { Partner as PartnerType } from "src/types/partner";
import { columns } from "./columns/PartnerColumns";
import { manageDevicePartner } from "src/api/cctv/cctv-api";

type SelectedPartner = {
  id?: string | number;
  name?: string;
} & PartnerType;

const Partner = ({ detail, callCctvData }: any) => {
  const [partnerList, setPartnerList] = useState<PartnerType[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<SelectedPartner[]>();
  const [partnerModal, setPartnerModal] = useState<Modal>({ isOpen: false });
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams: any = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const cctvId = id || queryParams?.id;
  console.log("cctvId", cctvId);
  //파트너 목록 조회
  const { executeAsync: getPaList } = useApiOperation(getPartnerList);
  //파트너 저장
  const { executeAsync: editCctvDevicePartner } = useApiOperation(manageDevicePartner);

  const fetchPartnerList = useCallback(
    async (detail: CctvDevice) => {
      let ids = "";
      detail.cctvDevicePartnerList?.forEach((partner: CctvDevicePartner, index: number) => {
        if (partner.cmdType !== "D" && partner.isDeleted === false) {
          if (ids.length === 0) {
            ids = String(partner.partnerId);
          } else {
            ids = ids + "," + String(partner.partnerId);
          }
        }
      });

      if (ids.length > 0) {
        const { data, status } = await getPaList({
          id: ids,
        });
        if (status >= 200 && status < 300) {
          const responseData = data.data.content;
          // console.log("responseData", responseData);
          setSelectedPartner(responseData);
          setPartnerList(responseData);
        }
      }
    },
    [getPaList],
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns: columns,
      data: partnerList,
      cctv: detail,
      fetchPartnerList: fetchPartnerList,
      initialState: {
        pageSize: 1000,
      },
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  useEffect(() => {
    console.log("detail", detail);
    if (detail && detail.cctvDevicePartnerList && detail.cctvDevicePartnerList.length > 0) {
      fetchPartnerList(detail);
    }
  }, [fetchPartnerList, detail]);

  return (
    <>
      <div className="contents-container__wrap-contents">
        <div className="py20 pr30" style={{ maxWidth: tableWidth + 30 }}>
          <div className="flex-row flex-center-end">
            <BaseButton title="+ 파트너 추가" onClick={() => setPartnerModal({ isOpen: true })} />
          </div>
        </div>
        <div className="inner-tab-table pr30">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                        {/* <div className="ic_sort"></div> */}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {page.map((row: any) => {
                prepareRow(row);

                return (
                  <div
                    {...row.getRowProps()}
                    className={`base-table__tr ${row.values.floor ? "bg-gray" : ""}`}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {partnerModal.isOpen && (
          <PartnerSelectModal
            onCanceled={() => setPartnerModal({ isOpen: false })}
            onAdded={async (partner: PartnerType[]) => {
              const partnerIds: string[] = partner.map((partner) => String(partner.id));
              setSelectedPartner(partner);
              const makePartnerData: Array<CctvDevicePartner> = [];
              partnerIds.forEach((value: string, index: number) => {
                const cdp: CctvDevicePartner = {
                  partnerId: String(value),
                  cmdType: "C",
                };
                makePartnerData.push(cdp);
              });

              if (makePartnerData.length > 0) {
                const cloneCctvDetail = _.cloneDeep(detail);
                //이미 선택 된 것은 추가 되지 안도록 처리
                let notDuplicate: Array<CctvDevicePartner> = [];
                makePartnerData.forEach((data: CctvDevicePartner) => {
                  if (
                    cloneCctvDetail.cctvDevicePartnerList &&
                    cloneCctvDetail.cctvDevicePartnerList.length > 0
                  ) {
                    const find = cloneCctvDetail.cctvDevicePartnerList.find(
                      (pa: any) => Number(pa.partnerId) === Number(data.partnerId),
                    );
                    if (!find) {
                      notDuplicate.push(data);
                    }
                  } else {
                    notDuplicate.push(data);
                  }
                });

                const res: any = await editCctvDevicePartner({
                  id: detail.id,
                  devicePartners: notDuplicate,
                });
                if (res.status > 199 && res.status < 400) {
                  setPartnerModal({ isOpen: false });
                }
                await callCctvData();
              }
            }}
            multiSelect
            defaultValues={selectedPartner || []}
          />
        )}
      </div>
      <div className="contents-container__btn-wrap px30">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            type="button"
            className="color-white size-large"
            onClick={() => {
              navigate("/mng/central/cctv");
            }}
          />
        </div>
        <div className="right-area"></div>
      </div>
    </>
  );
};

export default Partner;
