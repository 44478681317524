import { Partner } from "../partner/type";
import { Order } from "../public-types";

export type Platform = "ST" | "B_IOT"; //SmartThings, b.IoT
export type IMethod = "READ_STATUS" | "EXEC_COMMANDS"; //상태조회, 명령실행

export const Method = {
  READ_STATUS: "READ_STATUS",
  EXEC_COMMANDS: "EXEC_COMMANDS",
} as const;

export interface CommandPart {
  command?: string;
  arguments?: string[];
}
export interface PaPlatformAccount {
  email: string; //계정이메일
  platform: Platform; // IoT 구분
  principalName: string;
}

export interface PaPlatformDevice {
  id: string | number; // 디바이스 아이디
  platformAccount: PaPlatformAccount; // IoT 플랫폼 계정 정보
  name: string; // 디바이스 이름
  label: string; // 디바이스 레이블
  locationName: string; // 위치 이름
  type: string; // 디바이스 타입
  registeredDeviceId?: string | number; // 등록된 디바이스 아이디. 존재하면 등록된 디바이스
}
export interface PaRegisteredDevice {
  id: string | number; // 등록 디바이스 아이디
  displayName?: string; // 기기명 deprecated
  internalDisplayName?: string; // 기기명
  platformDevice?: PaPlatformDevice; //플랫폼 디바이스 정보
  partnerIds: string; // 파트너 아이디 목록
}

export interface PaPlatformDeviceParams {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  containsDisplayName?: string;
  containsPlatformAccountEmail?: string;
  containsPlatformDeviceId?: string;
  containsPlatformDeviceLabel?: string;
  containsPlatformDeviceName?: string;
  search001?: string;
}

export interface PaRegisteredDeviceControl {
  id: string | number; // 등록 디바이스 제어 아이디
  displayName?: string; //제어명
  method: IMethod; //제어 구분
  componentId: string; //컴포넌트 아이디
  capabilityId: string; // 기능 아이디
  commandPart?: CommandPart; // 명령 실행 파트
  executeResult?: string; // ** FE에서만 쓰는 Column용
  labels?: any;
  overridePlatformDeviceId?: string;
}

export interface AddSmartThingsControlPayload {
  displayName: string;
  method: string;
  componentId: string;
  capabilityId: string;
  commandPart?: CommandPart;
}

export interface RegisteredPartner {
  registeredDeviceId: string;
  partner: Partner;
}

export interface PaIotLabel {
  name: string;
  value: string;
}
