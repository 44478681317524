import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { addDevice, editDevice, getCctvList } from "src/api/cctv/cctv-api";
import { CctvDevice, CctvDeviceInsert } from "src/api/cctv/cctv-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";

const BasicInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [moveId, setMoveId] = useState();
  const [cctv, setCctv] = useState<CctvDevice>();

  const [modalOpen, setModalOpen] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);

  const { executeAsync: addCctvDevice } = useApiOperation(addDevice);
  const { executeAsync: editCctvDevice } = useApiOperation(editDevice);

  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: getCctvDetail } = useApiOperation(getCctvList);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    trigger,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<CctvDeviceInsert>({
    defaultValues: {
      cameraGroup: "",
      cameraId: "",
      name: "",
    },
  });

  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    register("cameraGroup", {
      required: requiredMessage,
      // maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });
    register("cameraId", {
      required: requiredMessage,
    });
    register("name", {
      required: requiredMessage,
    });
  }, [register, dirtyFields]);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const cctvId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const getDetailData = useCallback(
    (cctvId: string) => {
      if (cctvId) {
        getCctvDetail({ id: Number(cctvId) }).then((response) => {
          setLoadingBar(true);
          if (response.status >= 200 && response.status < 300) {
            const result: any = response.data.data.content[0];
            setCctv(result);
            setValue("cameraGroup", result.cameraGroup);
            setValue("cameraId", result.cameraId);
            setValue("name", result.name);
          }
          setLoadingBar(false);
        });
      }
    },
    [getCctvDetail, setLoadingBar, setValue],
  );

  const sendAddDevice = useCallback(
    (cctv: CctvDeviceInsert) => {
      addCctvDevice(cctv).then((response: any) => {
        console.log("response.data.data", response.data.data);
        setMoveId(response.data.data.content.id);
        setOpenFlag(true);
      });
    },
    [addCctvDevice],
  );

  const sendEditDevice = useCallback(
    (cctv: CctvDevice) => {
      editCctvDevice(cctv).then((response: any) => {
        console.log("response.data.data", response.data.data);
        setMoveId(response.data.data.content.id);
        setOpenFlag(true);
      });
    },
    [editCctvDevice],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      console.log("onSubmit data", data);
      //벨리데이션 체크

      // 데이터 세팅

      // console.log(saveModel);
      if (cctvId) {
        let cloneData = _.cloneDeep(cctv);
        if (cloneData) {
          cloneData.name = data.name;
          //수정
          await sendEditDevice(cloneData);
        }
      } else {
        //저장
        await sendAddDevice(data);
      }

      // e.target.reset();

      return false;
    },
    [cctv, cctvId, sendAddDevice, sendEditDevice],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);

    e.preventDefault();

    return false;
  };
  const goList = useCallback(() => {
    const path = PagePath.cctv.list;
    navigate(path);
  }, [navigate]);

  const moveDetail = useCallback(
    (id: number) => {
      const path = PagePath.cctv.detail.replace(":id", id!.toString());
      navigate(path);
    },
    [navigate],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "cctv") {
        navigate(PagePath.cctv.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "form", label: "CCTV 장치 > 기본정보" }];

  // // title 에 건물명 바인딩
  let name;
  if (cctv && cctv?.name) {
    name = cctv?.name;
  }

  useTitleOperation(name);
  //

  useEffect(() => {
    if (cctvId) {
      getDetailData(String(cctvId));
    }
  }, [getCctvDetail, cctvId, setLoadingBar, getDetailData]);

  return (
    <>
      <div className="contents-container__wrap-contents px30">
        {cctvId && (
          <ContentsIdSection
            title="기본정보"
            rightLables={[{ key: "CCTV ID", value: String(cctvId) }]}
          />
        )}
        {!cctvId && <ContentsIdSection title="기본정보" />}

        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div>
            {!cctvId && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">Camera Group</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax200">
                    <Controller
                      control={control}
                      name="cameraGroup"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            )}
            {!cctvId && (
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">Camera ID</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax200">
                    <Controller
                      control={control}
                      name="cameraId"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            )}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">장치명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax200">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <BaseInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        errorText={error?.message}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
          </div>
        </form>

        {openFlag && (
          <BaseModal isOpen={openFlag} btnRightTitle="확인" onClick={() => moveDetail(moveId!)}>
            <span>저장 되었습니다.</span>
          </BaseModal>
        )}
        {confirmFlag && (
          <BaseModal
            isOpen={confirmFlag}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => goList()}
            onClose={() => setConfirmFlag(false)}
          >
            <div>
              <span>작성중인 내용이 있습니다.</span>
              <span>계속할까요?</span>
            </div>
          </BaseModal>
        )}
      </div>

      <div className="contents-container__btn-wrap px30">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            className="color-white size-large"
            onClick={() => goList()}
          />
        </div>
        <div className="right-area">
          <BaseButton
            title="저장"
            type={"button"}
            className=" size-large"
            onClick={handleSubmit(onSubmit, onError)}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
