import { MediaFile } from "../public-types";

/*
  공지사항 등록
*/
export interface NoticeAddModel {
  id?: number;
  title?: string;
  content?: string;
  targets?: Array<any>;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  products?: any;
  type?: string;
  isDisplayed?: boolean;
  displayStartDate?: string;
  displayEndDate?: string;
  partnerId?: string;
  contracts?: Array<any>;
  buildings?: Array<any>;
}

/*
  공지사항 수정
*/
export interface NoticeEditModel extends NoticeAddModel {
  id: number;
}

/*
  공지사항
*/
export interface NoticeModel {
  id: number;
  title: string;
  content: string;
  targets?: string[];
  products?: NoticeProductModel[];
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  isDisplayed: boolean;
  displayStartDate?: string;
  displayEndDate?: string;
  contracts?: any;
  buildings?: any;
  partnerId?: string;
  type?: string;
  isDisplayTaapspace?: boolean;
  isDisplayTaap?: boolean;
}

export interface NoticeContractDto {
  id: number;
  contractId: number;
  cmdType?: "U" | "C" | "D";
}
export interface NoticeBuildingDto {
  id: number;
  buildingId: number;
  cmdType?: "U" | "C" | "D";
}
/*
  공지사항 상품
*/
export interface NoticeProductModel {
  id: number;
  name: string;
}

/*
  공지사항 미디어
*/
export interface NoticeMediaModel {
  id: number;
  fileStorageType: string;
  key: string;
  filename: string;
  contentType: string;
  fileSize: number;
  type: MediaType;
  url: string;
}

/*
  미디어 타입
*/
export enum MediaType {
  FILE = "FILE",
}
/*
  공지 유형
*/
export enum NoticeType {
  SERVICE = "SERVICE",
  SPACE = "SPACE",
}

/*
  공지사항 목록 조회 parameter
*/
export interface NoticeListParams {
  containsTitle?: string;
  containsContent?: string;
  containsTargets?: string;
  containsProductIds?: string;
  containsTypes?: string;
  search001?: string;
}

/*
  공지사항 목록 조회 response data
*/
export interface NoticeListResponseData {
  rows: Array<NoticeModel>;
}

/*
  공지사항 상세 조회 response data
*/
export interface NoticeDetailResponseData {
  notice: NoticeModel;
  medias: NoticeMediaModel[];
}

/*
  공지사항 등록 request data
*/
export interface NoticeCreateRequestData {
  notice: NoticeModel;
  medias: MediaFile[];
}

/*
  공지사항 등록 response data
*/
export interface NoticeCreateResponseData {
  notice: NoticeModel;
  medias: NoticeMediaModel[];
}

export interface NoticeUpdateRequestData extends NoticeCreateRequestData {}

/*
  공지사항 등록 response data
*/
export interface NoticeUpdateResponseData extends NoticeCreateResponseData {
  notice: NoticeModel;
  medias: NoticeMediaModel[];
}

/*
  공지사항 삭제 response data
*/
export interface NoticeDeleteResponseData {
  notice: {
    id: number;
  };
}
