import { useQuery } from "@tanstack/react-query";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { useApiOperation } from "src/api/hooks";
import { getPartners } from "src/api/partner";
import { getPopbillBizAllocAsync } from "src/api/popbill/popbill-api";
import {
  PopbillBizAddRequest,
  PopbillBizAlloc,
  PopbillBizDetailModel,
} from "src/api/popbill/popbill-types";
import { BaseButton, BaseInput } from "src/components";
import { ArticleSection } from "src/components/ArticleSection";
import GoToListButton from "src/components/GoToListButton";
import { checkValidateOrgNumber, companyRegistrationHypen } from "src/utils/common-util";
import PopbillVerifiedInfo from "../detail/components/PopbillVerifiedInfo";
import { useEffect, useMemo } from "react";

type Props = {
  control: Control<PopbillBizAddRequest>;
  popbill?: PopbillBizDetailModel;
  register: UseFormRegister<PopbillBizAddRequest>;
};

const BasicInfoForm = ({ control, popbill, register }: Props) => {
  const { executeAsync: getPopbillAllocs } = useApiOperation(getPopbillBizAllocAsync);
  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  // 파트너 권한 부여된 목록
  const { data: assignedPartners } = useQuery({
    queryKey: ["popbillAllocs"],
    queryFn: () => getPopbillAllocs({ pbBizId: String(popbill?.pbBizId) }),
    select: (response) => response.data.data.content,
    enabled: !!popbill,
  });

  const partnerIds = useMemo(
    () => assignedPartners?.map((item: PopbillBizAlloc) => item.partnerId).join(","),
    [assignedPartners],
  );

  // 파트너 목록 보여주기 위한 api
  const { data: partnerList } = useQuery({
    queryKey: ["partnerList", assignedPartners?.map((item) => item.partnerId)],
    queryFn: () =>
      getPartnersAsync({
        page: 0,
        size: 20,
        id: partnerIds,
      }),
    select: (response) => response.data.data.content || [],
    enabled: Boolean(partnerIds),
  });

  useEffect(() => {
    register("bizName", {
      required: { value: true, message: "법인명/상호는 필수 입력 항목입니다" },
    });
    register("bizRegNo", {
      validate: {
        requried: (businessNumber) => {
          let result = true;
          let message = "";
          let validateBusinessNumber = checkValidateOrgNumber(String(businessNumber));

          if (!businessNumber) {
            result = false;
            message = "사업자 등록번호는 필수 입력 항목입니다.";
          } else if (!validateBusinessNumber) {
            result = false;
            message = "사업자 등록번호는 최소 10자리 이상이어야 합니다.";
          }

          return result || message;
        },
      },
    });
  }, [register]);

  return (
    <div className="contents-container__wrap">
      <div className="contents-container__wrap-contents">
        <ArticleSection title="연동 대상" className="pt20 pb0 px0">
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">법인명/상호</p>
            </div>
            <div className="contents-container__grid-contents">
              <Controller
                control={control}
                name={"bizName"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseInput
                    onChange={onChange}
                    value={value}
                    className="minmax400"
                    placeholder="팝빌 등록 법인명/상호를 입력해주세요"
                    errorText={error?.message}
                  />
                )}
              ></Controller>
            </div>
          </section>
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className="required">사업자 등록번호</p>
            </div>
            <div className="contents-container__grid-contents">
              <Controller
                control={control}
                name={"bizRegNo"}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <BaseInput
                    maxLength={13}
                    onChange={onChange}
                    value={companyRegistrationHypen(value || "")}
                    className="minmax400"
                    placeholder="팝빌 등록 사업자 등록번호를 입력해주세요"
                    errorText={error?.message}
                    disabled={!!popbill}
                  />
                )}
              ></Controller>
            </div>
          </section>

          {popbill && (
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>파트너 권한 부여</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton title="선택" className="color-white" disabled />
                  <ul className="list-none flex-center flex-wrap pl10 ">
                    {partnerList?.map((partner, idx) => (
                      <li key={partner.id} className="mr15">
                        <span>{partner.id} </span>
                        <span>{`(${partner.name})`}</span>
                        {idx !== partnerList?.length - 1 && <span> ,</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          )}
        </ArticleSection>

        {popbill && (
          <ArticleSection title="연동 상태" className="px0 contents-container__divide-top">
            <PopbillVerifiedInfo popbill={popbill} />
          </ArticleSection>
        )}
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton title="저장" type="submit" className="size-large" />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoForm;
