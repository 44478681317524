import _ from "lodash";
import qs from "qs";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Cell } from "react-table";
import { manageDevicePartner } from "src/api/cctv/cctv-api";
import { useApiOperation } from "src/api/hooks";
import { deleteRegisteredDevicePartnerAsync } from "src/api/iot/iot-api";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal } from "src/components";
import { Partner } from "src/types/partner";

export const columns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 140,
    Cell: (props: Cell<Partner>) => {
      return <p className="no-wrap">{props.value}</p>;
    },
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 340,
    Cell: (props: Cell<Partner>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "파트너 명",
    accessor: "name",
    width: 340,
    Cell: (props: Cell<Partner>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p className="no-wrap">{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "삭제",
    accessor: "bin",
    width: 240,
    Cell: (props: any) => {
      const [confirmModalOpen, setConfirmModalOpen] = useState<Modal>({ isOpen: false });
      const partnerId = props.row.original.id;

      const { executeAsync: editCctvDevicePartner } = useApiOperation(manageDevicePartner);

      const deletePartner = async (partnerId: string) => {
        let devicePartner = props.cctv.cctvDevicePartnerList.find(
          (pt: any) => Number(pt.partnerId) === Number(partnerId),
        );
        if (devicePartner) {
          devicePartner.cmdType = "D";
          const { status }: any = await editCctvDevicePartner({
            id: String(props.cctv.id),
            devicePartners: [devicePartner],
          });

          setConfirmModalOpen({ isOpen: false });
          const cloned = _.cloneDeep(props.cctv);
          cloned.cctvDevicePartnerList.forEach((data: any) => {
            if (Number(data.partnerId) === Number(partnerId)) {
              data.cmdType = "D";
            }
          });
          if (props.fetchPartnerList) {
            await props.fetchPartnerList(cloned);
          }
        }
      };
      return (
        <div className="">
          <button
            className="base-trash-btn"
            onClick={() => {
              setConfirmModalOpen({ isOpen: true, message: "정말 삭제하시겠습니까?" });
            }}
          ></button>
          {confirmModalOpen.isOpen && (
            <BaseModal
              isOpen={true}
              btnLeftTitle="취소"
              btnRightTitle="삭제"
              onClose={() => setConfirmModalOpen({ isOpen: false })}
              onClick={() => deletePartner(String(partnerId))}
            >
              <p className="pre-formatted">{confirmModalOpen.message}</p>
            </BaseModal>
          )}
        </div>
      );
    },
  },
];
