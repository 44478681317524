import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getNoticeAsync, postNoticeAsync, putNoticeAsync } from "src/api/notice/notice-api";
import {
  NoticeBuildingDto,
  NoticeContractDto,
  NoticeMediaModel,
  NoticeModel,
  NoticeType,
} from "src/api/notice/notice-types";
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseTextarea,
  BaseToggle,
  ContentsIdSection,
  ContentsTitle,
} from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { YmdFormat } from "src/utils";
import { Modal, NoticeFormData } from "../notice-types";
import BuildingSelectModal from "./components/BuildingSelectModal";
import ContractSelectModal from "./components/ContractSelectModal";
import { getContractApply } from "src/api/contract/contract-api";
import { getBuildingsAsync } from "src/api/building/building-api";
import MarkdownEditor from "src/components/MarkdownEditor";
import NoticeDetail from "../details/NoticeDetail";
import { FullDatePicker } from "src/components/FullDatePicker";

/*
  공지사항 등록 or 수정
*/
const INVITEABLE_CONTRACT_STEP = [
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
];

type Contract = {
  contractId: number;
  contractApplyNumber: string;
  contractStep?: ContractStep;
  spaceProductName: string;
};
type Building = {
  buildingId: number;
  buildingName: string;
};

const NoticeForm = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      console.log("clickBreadCrumb", crumb);
      if (crumb.value === "notice") {
        navigate(PagePath.notice.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "notice", label: "공지사항" }];

  // 공지사항 상세 조회 (수정일 경우)
  const noticeId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [notice, setNotice] = useState<NoticeModel | undefined>();
  const [isContractSelectModalOpen, setIsContractSelectModalOpen] = useState({ isOpen: false });
  const [isBuildingSelectModalOpen, setIsBuildingSelectModalOpen] = useState({ isOpen: false });

  const { executeAsync: getNotice } = useApiOperation(getNoticeAsync);
  const { executeAsync: postNotice } = useApiOperation(postNoticeAsync);
  const { executeAsync: putNotice } = useApiOperation(putNoticeAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 작성취소 모달
  const [uncreateModal, setUncreateModal] = useState<Modal>({
    isOpen: false,
  });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    trigger,
    formState: { errors },
  } = useForm<NoticeFormData>({
    defaultValues: {
      title: "",
      content: "",
      isDisplayTaap: false,
      isDisplayTaapspace: false,
      isDisplayed: false,
      displayStartDate: "",
      displayEndDate: "",
    },
  });

  const fetchApi = useCallback(
    async (noticeId) => {
      const { data } = await getNotice({ noticeId });

      if (data.data.notice) {
        const notice = data.data.notice;
        setNotice(notice);
        setValue("title", notice.title);
        setValue("isDisplayTaap", notice.isDisplayTaap);
        setValue("isDisplayTaapspace", notice.isDisplayTaapspace);
        setValue("content", notice.content);
        setValue("isDisplayed", notice.isDisplayed);

        if (notice.displayEndDate && notice.displayStartDate) {
          setValue("displayStartDate", notice.displayStartDate);
          setValue("displayEndDate", notice.displayEndDate);
        }
      }
    },
    [getNotice, setValue],
  );

  useEffect(() => {
    if (noticeId) {
      fetchApi(noticeId);
    }
  }, [fetchApi, noticeId]);

  useEffect(() => {
    const requiredMessage = "필수입력항목입니다";
    register("title", {
      required: requiredMessage,
    });
    register("content", {
      required: requiredMessage,
    });
    register("displayStartDate", {
      required: requiredMessage,
    });
    register("displayEndDate", {
      required: requiredMessage,
    });
    register("isDisplayTaap", {
      validate: (value: boolean | undefined) => {
        const values = getValues();
        return value || values.isDisplayTaapspace || requiredMessage;
      },
    });
  }, [register, getValues]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      e.preventDefault();
      let noticeData: NoticeFormData = {
        title: "",
        content: "",
        isDisplayed: false,
        isDisplayTaap: false,
        isDisplayTaapspace: false,
        displayStartDate: "",
        displayEndDate: "",
      };

      let tempNoticeData = { ...noticeData, ...data };

      tempNoticeData.displayStartDate = moment(data.displayStartDate).format(
        YmdFormat.WITH_TIME_ZONE,
      );
      tempNoticeData.displayEndDate = moment(data.displayEndDate).format(YmdFormat.WITH_TIME_ZONE);

      const { ...payload } = tempNoticeData;

      console.log("페이로드", payload);
      if (noticeId) {
        // 수정
        setConfirmModal({
          isOpen: true,
          message: "수정하시겠습니까?",
          type: "editNotice",
          payload: payload,
        });
      } else {
        // 등록
        setConfirmModal({
          isOpen: true,
          message: "저장하시겠습니까?",
          type: "addNotice",
          payload: payload,
        });
      }

      e.target.reset();
      // return false;
    },
    [noticeId],
  );

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    // console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  const onAddedContractSelectModal = (
    contractList: {
      contractId: number;
      contractApplyNumber: string;
      spaceProductName: string;
    }[],
  ) => {
    if (!contractList) return;
    setIsContractSelectModalOpen({ isOpen: false });
  };
  const onAddedBuildingSelectModal = (
    buildingList: {
      buildingId: number;
      buildingName: string;
    }[],
  ) => {
    if (!buildingList) return;
    setIsBuildingSelectModalOpen({ isOpen: false });
  };

  // 데이터를 하나라도 입력했는지?
  const isChangedData = () => {
    const defaultValues: NoticeFormData = {
      title: "",
      content: "",
      isDisplayed: false,
      displayStartDate: "",
      displayEndDate: "",
    };

    const stringifyDefaultValues = JSON.stringify(defaultValues);
    const stringifyData = JSON.stringify(getValues());

    return stringifyDefaultValues === stringifyData ? false : true;
  };

  useTitleOperation(notice?.title);
  return (
    <div>
      <ContentsTitle title="공지사항" breadCrumbs={breadCrumbs} clickBreadCrumb={clickBreadCrumb} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="contents-container__wrap-contents">
              <ContentsIdSection title="공지사항 정보" id={noticeId} />
              <article className="contents-container__1200">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">게시 위치</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <Controller
                        control={control}
                        name="isDisplayTaap"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <>
                              <BaseCheckbox
                                key={"Taap"}
                                id={"Taap"}
                                name={name}
                                value={value}
                                label={"Taap"}
                                checked={value}
                                onChange={onChange}
                                className="mr16"
                              />
                            </>
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="isDisplayTaapspace"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <>
                              <BaseCheckbox
                                key={"TaapSpace"}
                                id={"TaapSpace"}
                                name={name}
                                value={value}
                                label={"TaapSpace"}
                                checked={value}
                                onChange={(e) => {
                                  onChange(e);
                                  trigger("isDisplayTaap"); // validation을 수동으로 trigger
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </div>
                    {errors && <p className="validation-text">{errors.isDisplayTaap?.message}</p>}
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">공지 유형</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax500">
                      <p>서비스 이용</p>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">제목</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax500">
                      <Controller
                        control={control}
                        name="title"
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder="제목을 입력해 주세요"
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">내용</p>
                  </div>
                  <div className="contents-container__grid-contents contents-container__1200">
                    <div>
                      {(!noticeId || getValues("content") === "") && (
                        <Controller
                          control={control}
                          name="content"
                          render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                            return (
                              <MarkdownEditor
                                value={value}
                                onChange={onChange}
                                errorText={error?.message}
                              />
                            );
                          }}
                        ></Controller>
                      )}

                      {noticeId && getValues("content") && (
                        <Controller
                          control={control}
                          name="content"
                          defaultValue={""}
                          render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                            return (
                              <MarkdownEditor
                                value={value}
                                onChange={onChange}
                                errorText={error?.message}
                              />
                            );
                          }}
                        ></Controller>
                      )}
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">공개 기간</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="minmax200">
                        <Controller
                          control={control}
                          name="displayStartDate"
                          render={({
                            field: { onChange, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <FullDatePicker
                                selectedDate={value ? moment(value).toDate() : null}
                                minDate={moment().toDate()}
                                minTime={
                                  moment(value || new Date()).isSame(moment(), "day")
                                    ? moment()
                                        .startOf("hour")
                                        .minutes(Math.floor(moment().minutes() / 1) * 1)
                                        .toDate()
                                    : moment().startOf("day").toDate()
                                }
                                maxTime={moment().endOf("day").toDate()}
                                setDate={(selectedDate: Date) => {
                                  const date = moment(selectedDate).format(
                                    YmdFormat.WITH_TIME_ZONE,
                                  );
                                  onChange(date);
                                }}
                                timeIntervals={5}
                                className="minmax180"
                                errorText={error?.message}
                              />
                            </>
                          )}
                        ></Controller>
                      </div>
                      <span className="mx10">~</span>
                      <div className="minmax200">
                        <Controller
                          control={control}
                          name="displayEndDate"
                          render={({
                            field: { onChange, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <FullDatePicker
                                selectedDate={value ? moment(value).toDate() : null}
                                minDate={moment().toDate()}
                                minTime={
                                  moment(value || new Date()).isSame(moment(), "day")
                                    ? moment()
                                        .startOf("hour")
                                        .minutes(Math.floor(moment().minutes() / 1) * 1)
                                        .toDate()
                                    : moment().startOf("day").toDate()
                                }
                                maxTime={moment().endOf("day").toDate()}
                                setDate={(selectedDate: Date) => {
                                  const date = moment(selectedDate).format(
                                    YmdFormat.WITH_TIME_ZONE,
                                  );
                                  onChange(date);
                                }}
                                className="minmax180"
                                timeIntervals={5}
                                errorText={error?.message}
                              />
                            </>
                          )}
                        ></Controller>
                      </div>
                    </div>
                    {errors?.displayStartDate?.message || errors?.displayEndDate?.message ? (
                      <p className="validation-text">
                        {errors?.displayStartDate?.message || errors?.displayEndDate?.message}
                      </p>
                    ) : null}
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">공개 여부</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <Controller
                        control={control}
                        name="isDisplayed"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <BaseToggle
                              checked={value}
                              onChange={(value: boolean) => {
                                onChange(value);
                              }}
                              name={name}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
            {/* 버튼영역 */}
            <div className="contents-container__btn-wrap">
              <div className="left-area">
                <BaseButton
                  title="목록으로"
                  className="color-white size-large"
                  onClick={() => {
                    if (isChangedData()) {
                      setUncreateModal({
                        message: "작성중인 내용이 있습니다. 계속 할까요?",
                        isOpen: true,
                      });
                    } else {
                      if (noticeId) {
                        // 수정화면에서는 목록으로 바로이동
                        navigate(PagePath.notice.list);
                      } else {
                        // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                        navigate(-1);
                      }
                    }
                  }}
                />
              </div>
              <div className="right-area">
                <BaseButton type="submit" title="저장" className=" size-large" />
              </div>
            </div>
          </form>

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          {confirmModal.isOpen && (
            <BaseModal
              isOpen={confirmModal.isOpen}
              btnLeftTitle={
                confirmModal.type === "addNotice" || confirmModal.type === "editNotice"
                  ? "취소"
                  : "머무르기"
              }
              btnRightTitle={
                confirmModal.type === "addNotice" || confirmModal.type === "editNotice"
                  ? "확인"
                  : "나가기"
              }
              onClick={async () => {
                if (confirmModal.type === "addNotice") {
                  setConfirmModal({ isOpen: false });
                  const { data } = await postNotice({ notice: confirmModal.payload! });
                  if (data.data) {
                    navigate(PagePath.notice.list);
                  }
                } else if (confirmModal.type === "editNotice") {
                  setConfirmModal({ isOpen: false });
                  const { data } = await putNotice({
                    notice: confirmModal.payload!,
                    id: String(noticeId)!,
                  });
                  if (data.data) {
                    navigate(`${PagePath.notice.detail.replace(":id", String(noticeId))}`);
                  }
                } else if (confirmModal.type === "checkChanged") {
                  if (noticeId) {
                    setConfirmModal({ isOpen: false });
                    navigate(`${PagePath.notice.detail.replace(":id", String(noticeId))}`);
                  } else {
                    setConfirmModal({ isOpen: false });
                    navigate(`${PagePath.notice.list}`);
                  }
                }
              }}
              onClose={() => setConfirmModal({ isOpen: false })}
            >
              {confirmModal.message}
            </BaseModal>
          )}

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>

          {/* 저장된것이 있을때 확인하는 모달 */}
          <BaseModal
            isOpen={uncreateModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              if (noticeId) {
                // 수정화면에서는 목록으로 바로이동
                navigate(PagePath.notice.list);
              } else {
                // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                navigate(-1);
              }
            }}
            onClose={() => setUncreateModal({ isOpen: false })}
          >
            <p>{uncreateModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default NoticeForm;
