import _ from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { addDevice, editDevice, getCctvList } from "src/api/cctv/cctv-api";
import { CctvDevice, CctvDeviceInsert } from "src/api/cctv/cctv-type";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseInput, BaseModal, ContentsIdSection, ContentsTitle } from "src/components";
import { useLoadingBarContext, useTitleOperation } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import BaseInfo from "../form/basicInfo/BasicInfo";
import Partner from "../detail/partnerLink/Partner";
const tabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "partnerLink", label: "파트너" },
  // { value: "로그", label: "로그" },
  // { value: "adminMemo", label: "관리자 메모" }, // api 만들어지면 노출예정
];

const CctvForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [moveId, setMoveId] = useState();
  const [cctv, setCctv] = useState<CctvDevice>();
  const [addCctv, setAddCctv] = useState<CctvDeviceInsert>();
  const [modalOpen, setModalOpen] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [confirmFlag, setConfirmFlag] = useState(false);

  const { executeAsync: addCctvDevice } = useApiOperation(addDevice);
  const { executeAsync: editCctvDevice } = useApiOperation(editDevice);

  const { setLoadingBar } = useLoadingBarContext();
  const { executeAsync: getCctvDetail } = useApiOperation(getCctvList);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    trigger,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<CctvDeviceInsert>({
    defaultValues: {
      cameraGroup: "",
      cameraId: "",
      name: "",
    },
  });

  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    register("cameraGroup", {
      required: requiredMessage,
      // maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });
    register("cameraId", {
      required: requiredMessage,
    });
    register("name", {
      required: requiredMessage,
    });
  }, [register, dirtyFields]);

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const cctvId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const getDetailData = useCallback(
    (cctvId: string) => {
      if (cctvId) {
        getCctvDetail({ id: Number(cctvId) }).then((response) => {
          setLoadingBar(true);
          if (response.status >= 200 && response.status < 300) {
            const result: any = response.data.data.content[0];
            setCctv(result);
            setValue("cameraGroup", result.cameraGroup);
            setValue("cameraId", result.cameraId);
            setValue("name", result.name);
          }
          setLoadingBar(false);
        });
      }
    },
    [getCctvDetail, setLoadingBar, setValue],
  );

  const sendAddDevice = useCallback(
    (cctv: CctvDeviceInsert) => {
      addCctvDevice(cctv).then((response: any) => {
        console.log("response.data.data", response.data.data);
        setMoveId(response.data.data.content.id);
        setOpenFlag(true);
      });
    },
    [addCctvDevice],
  );

  const sendEditDevice = useCallback(
    (cctv: CctvDevice) => {
      editCctvDevice(cctv).then((response: any) => {
        console.log("response.data.data", response.data.data);
        setMoveId(response.data.data.content.id);
        setOpenFlag(true);
      });
    },
    [editCctvDevice],
  );

  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      console.log("onSubmit data", data, e);
      //벨리데이션 체크

      // 데이터 세팅

      // console.log(saveModel);
      if (cctvId) {
        let cloneData = _.cloneDeep(cctv);
        if (cloneData) {
          cloneData.name = data.name;
          //수정
          sendEditDevice(cloneData);
        }
      } else {
        //저장
        sendAddDevice(data);
      }

      e.target.reset();

      return false;
    },
    [cctvId, sendAddDevice],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }

    return tabs[0];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      if (!queryParams.id && tab.value !== "basicInfo") {
        setModalOpen(true);
        return;
      }

      // console.log("clickTab", tab);
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      // console.log("clickBreadCrumb", crumb);
      if (crumb.value === "cctv") {
        navigate(PagePath.cctv.list);
      }
    },
    [navigate],
  );

  const breadCrumbs = [{ value: "form", label: "CCTV 장치" }];

  // // title 에 건물명 바인딩
  let name;
  if (cctv && cctv?.name) {
    name = cctv?.name;
  }

  useTitleOperation(name);
  //

  const callCctvData = useCallback(async () => {
    getCctvDetail({ id: Number(cctvId) }).then((response) => {
      setLoadingBar(true);
      if (response.status >= 200 && response.status < 300) {
        const result: any = response.data.data.content[0];
        setCctv(result);

        // fetchPartner(result?.organization?.partnerId);
      }
      setLoadingBar(false);
    });
  }, [cctvId, getCctvDetail, setLoadingBar]);

  useEffect(() => {
    console.log("cctvId", cctvId, activeTab?.value);
    if (cctvId) {
      callCctvData();
    }
  }, [getCctvDetail, cctvId, setLoadingBar, getDetailData, callCctvData]);

  return (
    <div>
      <ContentsTitle
        title="기본정보"
        tabs={tabs}
        activeTab={activeTab}
        clickTab={clickTab}
        breadCrumbs={breadCrumbs}
        clickBreadCrumb={clickBreadCrumb}
      />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BaseInfo />}
        {activeTab?.value === "partnerLink" && (
          <Partner detail={cctv} callCctvData={callCctvData} />
        )}
      </div>
    </div>
  );
};

export default CctvForm;
