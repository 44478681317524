import React from "react";

interface BaseCheckboxState {
  id: string;
  name: string;
  label?: string;
  className?: string;
  checked?: boolean;
  onChange?: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  value?: any;
  wrap?: boolean;
}

export const BaseCheckbox = (props: BaseCheckboxState) => {
  return (
    <div className={`d-flex flex-start ${props.className ? props.className : ""}`}>
      <input
        id={props.id}
        type="checkbox"
        name={props.name}
        checked={props.checked}
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange && props.onChange(e.target.checked, e)
        }
        disabled={props.disabled}
      ></input>
      <label htmlFor={props.id} className="d-flex">
        <div className="base-checkbox"></div>
        {props.label && (
          <span className={props.wrap ? "ml8 font14" : "ml8 font14 no-wrap"}>{props.label}</span>
        )}
      </label>
    </div>
  );
};
