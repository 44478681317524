import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Cell,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useApiOperation } from "src/api/hooks";
import { patchPartnerFeatureActiveAsync } from "src/api/partner";
import { BaseToggle } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PartnerFeatures } from "src/types/partner";
import { featureNameToText } from "../types";
type Props = {
  features?: PartnerFeatures[];
  setPartner?: Function;
};

const PartnerFeature = ({ features, setPartner }: Props) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { id } = useParams();
  const { executeAsync: patchFeatureActive } = useApiOperation(patchPartnerFeatureActiveAsync);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "feature명",
        accessor: "name",
        width: 314,
        Cell: ({ value }: Cell<PartnerFeatures>) => <p className="w-100 text-left">{value}</p>,
      },
      {
        Header: "설명",
        accessor: "description",
        width: 456,
        Cell: ({ row }: Cell<PartnerFeatures>) => {
          const name = row.original.name;
          return <p className="w-100 text-left pre-formatted">{featureNameToText(name)}</p>;
        },
      },
      {
        Header: "활성화 여부",
        accessor: "active",
        width: 100,
        Cell: ({ row, value }: Cell<PartnerFeatures>) => {
          const featureName = row.original.name;

          return (
            <>
              <BaseToggle
                checked={value}
                onChange={async (value: boolean) => {
                  if (id) {
                    setLoadingBar(true);
                    const response = await patchFeatureActive({
                      partnerId: id,
                      featureName,
                      active: value,
                    });
                    if (response.status >= 200 && response.status <= 299) {
                      const newData = response.data.data.partner;
                      if (setPartner) {
                        setPartner(newData);
                      }
                    }
                    setLoadingBar(false);
                  }
                }}
              />
            </>
          );
        },
      },
    ],
    [id],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: features || [],
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <div className="contents-container__wrap-contents">
      <div className="mb10">
        <h2>feature</h2>
      </div>
      <section className="inner-tab-table">
        <div className="my20">
          <div {...getTableProps()} className="base-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className={`base-table__tr`}>
                    {row.cells.map((cell: any) => {
                      return (
                        <div {...cell.getCellProps()} className="base-table__td">
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {rows.length === 0 && (
              <div className="base-table__tr" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartnerFeature;
