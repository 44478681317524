// 건물 Table Columns
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";
import { Link } from "react-router-dom";

export const columns = [
  {
    Header: "CCTV ID",
    accessor: "id",
    // sticky: "left",
    width: 120,
    Cell: ({ value }: any) => {
      const detailPath = `${PagePath.cctv.detail.replace(":id", `${value}`)}`;
      return (
        <Link to={detailPath} className="text-hilight">
          {value}
        </Link>
      );
    },
  },

  {
    Header: "Camera Group",
    accessor: "cameraGroup",
    width: 160,
    
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left ellipsis2"><p>{value}</p></div>
    },
  },
  {
    Header: "Camera ID",
    accessor: "cameraId",
    width: 120,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left ellipsis2"><p>{value}</p></div>
    },
  },
  {
    Header: "장치명",
    accessor: "name",
    width: 160,
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left ellipsis2"><p>{value}</p></div>
    },
  },
  {
    Header: "파트너",
    accessor: "cctvDevicePartnerList",
    width: 160,
    Cell: ({ value, partnerList }: any) => {
      if (partnerList.length > 0) {
        if (value) {
          const firstPartnerId = value[0].partnerId;
          const firstPartner = partnerList.find(
            (pa: any) => Number(firstPartnerId) === Number(pa.id),
          );

          const path = PagePath.partner.detail.replace(":id", firstPartnerId);
          if (value && value.length > 0) {
            return (
              <div className="w-100 text-left ellipsis2">
                <Link to={path} target="_blank" className="text-hilight mr4">
                  {firstPartner.name}
                </Link>
                {value && value.length > 1 && <span>외 {value && value.length - 1}</span>}
              </div>
            );
          } else return <p>-</p>;
        }
      } else {
        return <p>-</p>;
      }
    },
  },

  {
    Header: "생성일",
    accessor: "createdDate",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <>
          <BaseTooltip contents={value} type={"date"} />
        </>
      );
    },
  },
];
