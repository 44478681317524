import {
  NoticeBuildingDto,
  NoticeContractDto,
  NoticeProductModel,
  NoticeType,
} from "src/api/notice/notice-types";

// select box 공통 option
export type SelectOption = {
  value: string;
  label: string;
  index?: number;
  subOptions?: Array<SelectOption>;
};

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
};

// 공지사항 입력 form data
export type NoticeFormData = {
  title: string;
  content: string;
  targets?: string[];
  products?: NoticeProductModel[];
  type?: string;
  isDisplayed: boolean;
  isDisplayTaap?: boolean;
  isDisplayTaapspace?: boolean;
  displayStartDate: string;
  displayEndDate: string;
  medias?: [];
};

// 게시 위치
export const targetOptions: Array<SelectOption> = [
  { value: "ALL", label: "전체" },
  { value: "TAAP", label: "Taap" },
];

export const changeTextNoticeType = (type: string) => {
  switch (type) {
    case NoticeType.SERVICE:
      return "서비스 이용";
    case NoticeType.SPACE:
      return "공간 이용";
    default:
      return "-";
  }
};
