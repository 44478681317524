import { AxiosInstance, AxiosResponse } from "axios";
import { ApiPath } from "..";

import { ApiResponse } from "../public-types";
import qs from "qs";
import { CctvDevice, CctvDeviceInsert, CctvDevicePartner, CctvEventData } from "./cctv-type";

const getCctvList = (
  axios: AxiosInstance,
  params?: any,
): Promise<AxiosResponse<ApiResponse<{ content: CctvDevice[] }>>> => {
  const path = ApiPath.cctv.list;
  return axios.get<ApiResponse<{ content: CctvDevice[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

const addDevice = (
  axios: AxiosInstance,
  params?: CctvDeviceInsert,
): Promise<AxiosResponse<ApiResponse<CctvDevice>>> => {
  const path = ApiPath.cctv.add;
  return axios.post<ApiResponse<CctvDevice>>(path, { cctvDevice: params });
};

const editDevice = (
  axios: AxiosInstance,
  params?: CctvDevice,
): Promise<AxiosResponse<ApiResponse<CctvDevice>>> => {
  const path = ApiPath.cctv.edit.replace("{id}", String(params!.id!));
  return axios.post<ApiResponse<CctvDevice>>(path, { cctvDevice: params });
};

const manageDevicePartner = (
  axios: AxiosInstance,
  params?: {
    id: string; //device id,
    devicePartners: Array<CctvDevicePartner>;
  },
): Promise<AxiosResponse<ApiResponse<CctvDevice>>> => {
  console.log("params", params!.id!);
  const path = ApiPath.cctv.partner.replace("{id}", params!.id!);
  return axios.post<ApiResponse<CctvDevice>>(path, {
    cctvDevicePartnerList: params!.devicePartners!,
  });
};

const getCctvEventList = (
  axios: AxiosInstance,
  params?: any,
): Promise<AxiosResponse<ApiResponse<{ content: CctvEventData[] }>>> => {
  const path = ApiPath.cctv.eventList;
  return axios.get<ApiResponse<{ content: CctvEventData[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

export { getCctvList, addDevice, editDevice, manageDevicePartner, getCctvEventList };
