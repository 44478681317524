import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { BaseButton, BaseModal } from "src/components";

import moment from "moment";
import { useState } from "react";
import { getPartners } from "src/api/partner";
import { Partner } from "src/api/partner/type";
import {
  addPopbillBizAllocAsync,
  getPopbillBizAllocAsync,
  getPopbillDetailAsync,
  verifyPopbillBizApiAsync,
} from "src/api/popbill/popbill-api";
import { PopbillBizAlloc, PopbillBizDetailModel } from "src/api/popbill/popbill-types";
import { Modal } from "src/api/public-types";
import { ArticleSection } from "src/components/ArticleSection";
import GoToListButton from "src/components/GoToListButton";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { PagePath } from "src/pages/product/details";
import { companyRegistrationHypen } from "src/utils/common-util";
import PopbillVerifiedInfo from "./PopbillVerifiedInfo";

type Props = {
  refetchPopbill: () => void;
  popbill?: PopbillBizDetailModel;
};

const BasicInfoDetail = ({ refetchPopbill, popbill }: Props) => {
  const navigate = useNavigate();

  const { id: pbBizId } = useParams();
  // 파트너 목록 노출 모달
  const [partnerModal, setPartnerModal] = useState<Modal>({
    isOpen: false,
  });

  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // apis
  // 파트너 목록 조회
  const { executeAsync: getPartnersAsync } = useApiOperation(getPartners);

  // 팝빌 사업자 파트너 할당 조회
  const { executeAsync: getPopbillAllocs } = useApiOperation(getPopbillBizAllocAsync);

  // 팝빌 사업자 파트너 할당
  const { executeAsync: addPopbillBizAlloc } = useApiOperation(addPopbillBizAllocAsync);

  // 팝빌 사업자 검증
  const { executeAsync: verifyPopbillBizApi } = useApiOperation(verifyPopbillBizApiAsync);

  const { mutate: verifyPopbillBiz } = useMutation({
    mutationFn: () => verifyPopbillBizApi({ pbBizId: String(pbBizId) }),
    onSuccess: () => {
      refetchPopbill();
    },
  });

  // 파트너 권한 부여된 목록
  const { data: assignedPartners, refetch: refetchAssignedPartners } = useQuery({
    queryKey: ["popbillAllocs"],
    queryFn: () => getPopbillAllocs({ pbBizId: String(pbBizId) }),
    select: (response) => response.data.data.content,
  });

  // 파트너 목록 보여주기 위한 api
  const { data: partnerList } = useQuery({
    queryKey: ["partnerList", assignedPartners?.map((item: PopbillBizAlloc) => item.partnerId)],
    queryFn: () =>
      getPartnersAsync({
        page: 0,
        size: 20,
        id: assignedPartners?.map((item: PopbillBizAlloc) => item.partnerId).join(","),
      }),
    select: (response) => response.data.data,
    enabled: !!assignedPartners && assignedPartners.length > 0,
  });

  //팝빌 사업자 > 파트너 할당
  const { mutate: addPartnersInPopbillBiz } = useMutation({
    mutationFn: async (partners: Partner[]) => {
      console.log("partners", partners);
      const deletePartners = assignedPartners
        ?.filter((partner) => {
          const isSamePartner = partners.some((s) => String(s.id) === String(partner.partnerId));
          return !isSamePartner;
        })
        .map((partner) => ({
          ...partner,
          isDeleted: true,
        }));

      const newAddedPartners = partners.filter((partner) => {
        const isSamePartner = assignedPartners?.some(
          (s) => String(s.partnerId) === String(partner.id),
        );
        return !isSamePartner;
      });

      const popbillBizAllocList = [
        ...(deletePartners?.map((partner) => ({
          ...partner,
          isDeleted: true,
        })) || []),
        ...(newAddedPartners.map((partner) => ({
          partnerId: String(partner.id),
        })) || []),
      ];
      return addPopbillBizAlloc({
        pbBizId: String(pbBizId),
        popbillBizAllocList,
      });
    },
    onSuccess: ({ status }) => {
      // 모달 닫기
      setPartnerModal({ isOpen: false });
      setConfirmModal({ isOpen: false });

      refetchAssignedPartners();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <div className="contents-container__scroll">
      <div className="contents-container__wrap">
        <div className="contents-container__wrap-contents">
          <ArticleSection title="연동 대상" className="pt20 pb0 px0">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>법인명/상호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <span>{popbill?.bizName}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>사업자 등록번호</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <span>{companyRegistrationHypen(popbill?.bizRegNo || "")}</span>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>파트너 권한 부여</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton
                    title="선택"
                    className="color-white"
                    onClick={() => setPartnerModal({ isOpen: true })}
                  />
                  <ul className="list-none flex-center flex-wrap pl10 ">
                    {partnerList?.content?.map((partner, idx) => (
                      <li key={partner.id} className="mr15">
                        <span>{partner.id} </span>
                        <span>{`(${partner.code})`}</span>
                        {idx !== partnerList?.content?.length - 1 && <span> ,</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </ArticleSection>

          <ArticleSection title="연동 상태" className="px0 contents-container__divide-top">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>연동 상태</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start">
                  <BaseButton title="검증" className="mr20" onClick={() => verifyPopbillBiz()} />
                  <span>
                    {popbill?.verifiedDate
                      ? moment(popbill?.verifiedDate).format("YYYY-MM-DD HH:mm")
                      : ""}
                  </span>
                </div>
              </div>
            </section>

            {/* 연동 상태 */}
            <div className="mt20">
              <PopbillVerifiedInfo popbill={popbill} />
            </div>
          </ArticleSection>
        </div>

        {/* 버튼 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area">
            <GoToListButton />
          </div>
          <div className="right-area">
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => navigate(`${PagePath.popbill.form}?id=${pbBizId}`)}
            />
          </div>
        </div>
      </div>
      {partnerModal.isOpen && (
        <PartnerSelectModal
          onCanceled={() => setPartnerModal({ isOpen: false })}
          multiSelect
          onAdded={(selected) => {
            setConfirmModal({
              isOpen: true,
              title: "API 연동 권한을 부여하시겠습니까?",
              message: "권한을 부여하면 해당 파트너에서 API를 통해 시스템이 연동됩니다.",
              payload: selected,
            });
          }}
          defaultValues={partnerList?.content || []}
        />
      )}

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={confirmModal.title}
          onClick={() => addPartnersInPopbillBiz(confirmModal.payload)}
          onClose={() => setConfirmModal({ isOpen: false })}
          btnRightTitle="확인"
          btnLeftTitle="취소"
        >
          <span className="font12 text-primary3">{confirmModal.message}</span>
        </BaseModal>
      )}
    </div>
  );
};

export default BasicInfoDetail;
