import { contract_bill_user_errors, Order, PageMeta, Select } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import { columns } from "./columns/Columns";
import _ from "lodash";

import PartnerSelectModal from "src/components/partner/PartnerSelectModal";

import { useNavigate, useLocation } from "react-router-dom";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { useCallback, useEffect, useMemo, useState } from "react";
import qs from "qs";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getCctvList } from "src/api/cctv/cctv-api";
import { getPartnerList } from "src/api/partner";
import { CctvDevice, CctvDevicePartner } from "src/api/cctv/cctv-type";
const searchTypeOption: Select[] = [
  { label: "전체", value: "" },
  { label: "Camera Group", value: "CAMERA_GROUP" },
  { label: "Camera ID", value: "CAMERA_ID" },
  { label: "장치명", value: "CAMERA_NAME" },
];
const CctvList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [partners, setPartners] = useState<any>([]);

  const { executeAsync: getList } = useApiOperation(getCctvList);
  const { executeAsync: getPaList } = useApiOperation(getPartnerList);

  const [data, setData] = useState<any[]>([]);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [pagination, setPagination] = useState<PageMeta>();

  // console.log(location.search, "location.search");
  const queryParams: any = useMemo(() => {
    const _queryParams: any = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (c) => c,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }

    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (_queryParams?.id) {
      delete _queryParams?.id;
    }

    return _queryParams;
  }, [location]);

  const [params, setParams] = useState<any>({ ...queryParams });
  const fetchPartnerList = useCallback(
    async (ids: string) => {
      if (ids.length > 0) {
        const { data, status } = await getPaList({
          id: ids,
        });
        if (status >= 200 && status < 300) {
          const responseData = data.data.content;
          setPartnerList(responseData);
        }
      }
    },
    [getPaList],
  );
  const callList = useCallback(
    async (param: any) => {
      setLoadingBar(true);
      if (!param.keyword) {
        delete param.keyword;
      } else {
        param.keyword = decodeURIComponent(param.keyword || "");
      }
      if (!param.searchType) {
        delete param.searchType;
      }
      let response: any = await getList(param);

      if (response && response.status >= 200 && response.status <= 299) {
        setData(response.data.data.content);
        let partnerIds: any = [];
        // 사용된 파트너 명을 표시 하기 위해 대량으로 파트너 데이터 조회
        response.data.data.content.forEach((detail: CctvDevice, index: number) => {
          detail.cctvDevicePartnerList?.forEach((partner: CctvDevicePartner, index: number) => {
            partnerIds.push(partner.partnerId);
          });
        });
        const _ids = _.uniq(partnerIds);
        let ids = "";
        _ids.forEach((id: any, index: number) => {
          if (index === 0) {
            ids = String(id);
          } else {
            ids = ids + "," + String(id);
          }
        });
        await fetchPartnerList(ids);

        setPagination(response!.data!.meta!.pageMeta!);
        setParams({ ...params, ...param });
      } else {
        let message = errorMessageHandler(
          response.status,
          response.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = response.data.meta.errorCode ? response.data.meta.errorCode : response.status;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    },
    [fetchPartnerList, getList, openErrorModal, params, setLoadingBar],
  );

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: any) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true, encode: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [params, navigate, location.pathname],
  );

  const settingPartners = useCallback(
    (partners: any) => {
      // console.log("partners", partners);
      setPartners(partners[0]);
      setParams({ ...params, ...{ partnerId: partners[0].id, partnerName: partners[0].name } });
      setVisible(false);
    },
    [params],
  );

  const closePartnerPopup = () => {
    setVisible(false);
  };

  useEffect(() => {
    callList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          <div className="minmax140 mr8">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.partnerName || "")}
              placeholder="파트너명"
              readonly={true}
              onKeyUp={() => setVisible(true)}
              onClearClick={() => {
                const temp = _.cloneDeep(params);
                delete temp.partnerName;
                delete temp.partnerId;
                setParams({ ...temp });
              }}
              onSearchClick={() => setVisible(true)}
            />
            {visible && (
              <PartnerSelectModal
                defaultValues={partners || []}
                onAdded={settingPartners}
                onCanceled={closePartnerPopup}
              />
            )}
          </div>

          <div className="minmax160 mr8">
            <BaseSelect
              value={params.searchType}
              stateOptions={searchTypeOption}
              placeholder="검색 옵션"
              setStateValue={(searchType: string) => setParams({ ...params, searchType })}
            />
          </div>

          <div className="minmax220 mr16">
            <BaseInput
              type="text"
              value={decodeURIComponent(params?.keyword || "")}
              placeholder="검색어를 입력해주세요"
              onChange={(keyword: string) => setParams({ ...params, keyword })}
              onKeyUp={() => navigateWithQueryParams({ page: 0 })}
              onSearchClick={() => navigateWithQueryParams({ page: 0 })}
            />
          </div>
        </div>
        <div className="right-area">
          <BaseButton title="+ 장치 등록" onClick={() => navigate("form")} />
        </div>
      </div>
      {/* 샘플 */}
      <BaseTable
        data={data}
        partnerList={partnerList}
        columns={columns}
        pageIndex={Number(params?.page) || 0}
        totalPages={Number(pagination?.totalPages) || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page });
        }}
        disabledSortHeaders={
          [
            // "contractApplyNumber",
            // "spaceProductId",
            // "createdDate",
            // "modifiedDate",
          ]
        }
        orders={params?.sort?.orders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } });
          }
        }}
        noDataMessage={"등록된 장치가 없습니다."}
      />
    </div>
  );
};
export default CctvList;
