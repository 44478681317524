import { Sort } from "../public-types";

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
};

export type AdminEdit = {
  isEdit?: boolean;
  memo?: string;
  id?: string;
};

// 관리자 메모 타입
export type AdminMemoType =
  | "PRODUCT" //상품 관리자 메모
  | "BUILDING" // 건물 관리자 메모
  | "RESERVATION"
  | "WORKORDER" // 예약 관리자 메모;
  | "IOT"; // TODO::확인필요

//  관리자 메모 등록
export interface AdminMemoAddModel {
  id?: string; //건물_관리자메모_ID
  memo?: string; // 메모
  serviceId?: string; // 메모를 남길 서비스의 ID
  createdDate?: string; // 생성일
  createdBy?: string; // 생성자
  modifiedDate?: string; // 수정일
  modifiedBy?: string; // 수정자
}

export interface CommonDateNUser {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

/**
 * 메모 기본 객체
 */
export interface Memo extends CommonDateNUser {
  id?: string | number;
  parterId?: string | number;
  memo?: string;
  serviceId: string;
  serviceType: string;
  isDeleted?: boolean;
  isCentralOnly?: boolean; //central에서만 볼 수 있는지 여부. true : ctrl.room 에서 미노출 false : ctrl.room, central 모두 노출, default
}

export interface AdminMemoAddResponse {
  content: string;
}

// 관리자 메모 목록
export interface AdminMemoListReseponse {
  content: Array<Memo>;
}

export interface AdminMemoListParams {
  page?: number;
  size?: number;
  serviceId?: number;
  adminMemoType?: AdminMemoType;
}

// 관리자 메모 삭제
export interface AdminMemoDeleteModel {
  ids: Array<string>;
  adminMemoType: AdminMemoType;
}
/**
 * 2023-09-12 신규 관리자 메모의 서비스 타입 정의
 * 기존 타입은 호환의 문제로 일단 내버려 둠
 */
export enum ServiceTypes {
  //  정의되지 않은 타입
  SERVICE_UNRECOGNIZED = "SERVICE_UNRECOGNIZED",
  // 공지사항
  SERVICE_NOTICE = "SERVICE_NOTICE",
  //상담문의 질문
  SERVICE_QUESTION = "SERVICE_QUESTION",
  // 상담문의 답변
  SERVICE_QUESTION_ANSWER = "SERVICE_QUESTION_ANSWER",
  // 상품
  SERVICE_PRODUCT = "SERVICE_PRODUCT",
  // 건물
  SERVICE_BUILDING = "SERVICE_BUILDING",
  // 방문 일정 리마인드 ( 푸쉬 )
  SERVICE_CONTRACT_VISITOR_REMIND_PUSH = "SERVICE_CONTRACT_VISITOR_REMIND_PUSH",
  // 공용공간 예약 ( Taap, 캘린더 예약 )
  SERVICE_RESERVATION = "SERVICE_RESERVATION",
  // 공용공간 예약 리마인드 알림
  SERVICE_RESERVATION_REMIND = "SERVICE_RESERVATION_REMIND",
  // 공용공간 예약 캘린더
  SERVICE_RESERVATION_CALENDAR = "SERVICE_RESERVATION_CALENDAR",
  // 파일업로드 관련 //관리자 메모용 아님 !!!!! 참고//사용전  @ryan, @dany에게 문의 후 사용할 것
  SERVICE_ADMINMEMO = "SERVICE_ADMINMEMO",
  // 워크오더
  SERVICE_WORKORDER = "SERVICE_WORKORDER",
  // 계약 관리자 메모
  SERVICE_CONTRACT = "SERVICE_CONTRACT",
  // 빌링 관리자 메모
  SERVICE_BILLING_SCHEDULE = "SERVICE_BILLING_SCHEDULE",
  // 사업자
  SERVICE_ORGANIZATION = "SERVICE_ORGANIZATION",
  // 정산정보
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  // IoT
  SERVICE_IOT_DEVICE = "SERVICE_IOT_DEVICE",
  // 요금 룰
  SERVICE_PRICE_RULE = "SERVICE_PRICE_RULE",
  // Voc(Cs)
  SERVICE_CS_ITEM = "SERVICE_CS_ITEM",
  // 회원
  SERVICE_MEMBER = "SERVICE_MEMBER",
  // CCTV
  SERVICE_CCTV_DEVICE = "SERVICE_CCTV_DEVICE",
}
export type UnionServiceType = typeof ServiceTypes[keyof typeof ServiceTypes];

/**
 * 메모 기본 객체
 */
export interface Memo {
  id?: string | number;
  parterId?: string | number;
  memo?: string;
  serviceId: string;
  serviceType: string;
  isDeleted?: boolean;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

/**
 * sort 가능 필드
 * id : pk(등록순)
 * serviceType : 미디어 종류
 */
export interface RequsetAdminMemo {
  contentsList: [
    {
      serviceId: number;
      serviceType: string;
    },
  ];
  page?: number;
  size?: number;
  sort?: Sort;
}

export interface AdminMemosResponse {
  content: Array<Memo>;
}

export interface InsertAdminMemo {
  partnerId?: string;
  memo: string;
  serviceId: string;
  serviceType: ServiceTypes;
  isCentralOnly?: boolean;
}

export interface UpdateAdminMemo extends InsertAdminMemo {
  id?: string;
  isDeleted?: boolean;
}

export interface InsertOrUpdate {
  data: UpdateAdminMemo;
}

export interface DeleteAdminMemo {
  deleteUri: string;
}
